import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { pxToRem } from 'theme/util';

import stoolTopOff from 'assets/stool-top-off-mobile.svg';
import stoolMiddleOff from 'assets/stool-middle-off-mobile.svg';
import stoolBottomOff from 'assets/stool-bottom-off-mobile.svg';
import stoolTopOn from 'assets/stool-top-on-mobile.svg';
import stoolMiddleOn from 'assets/stool-middle-on-mobile.svg';
import stoolBottomOn from 'assets/stool-bottom-on-mobile.svg';
import stool1 from 'assets/stool-1-mobile.png';
import stool2 from 'assets/stool-2-mobile.png';
import stool3 from 'assets/stool-3-mobile.png';
import colors from 'theme/colors';
import { customClickEvent } from 'analytics';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import useHashScroll from 'hooks/useScrollHash';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        '& > *': {
            transition: 'all 1.5s ease',
        },
        margin: '0px auto',
        //width: 550,
    },
    headerContainer: {
        color: theme.colors.white,
        padding: '15px',
        textAlign: 'center',
    },
    contentWrapper: {
        maxWidth: 400,
        margin: '0px auto',
    },
    section1: {
        height: 275,
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '-5px',
        backgroundPositionY: '121px',
        maxWidth: 500,
        margin: '0px auto',
    },
    stoolLine1: {
        position: 'absolute',
        top: 13,
        left: 71,
    },
    section1Content: {
        color: theme.colors.white,
    },
    section1ContentPosition: {
        position: 'absolute',
        top: -2,
        left: 110,
        width: 260,
        fontSize: pxToRem(12),
        lineHeight: 1.1,
    },
    section2: {
        height: 280,
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '-5px',
        backgroundPositionY: '-5px',
        maxWidth: 500,
        margin: '0px auto',
    },
    stoolLine2: {
        position: 'absolute',
        top: 5,
        left: 40,
    },
    section2Content: {
        color: theme.colors.white,
    },
    section2ContentPosition: {
        position: 'absolute',
        top: -8,
        left: 230,
        width: 148,
        fontSize: pxToRem(12),
        lineHeight: 1.1,
    },
    section3: {
        height: 230,
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '-5px',
        backgroundPositionY: '-62px',
        maxWidth: 500,
        margin: '0px auto',
    },
    stoolLine3: {
        position: 'absolute',
        top: -100,
        left: 100,
        zIndex: 1,
    },
    stoolBottom: {
        position: 'absolute',
        top: -173,
        left: -5,
    },
    section3Content: {
        color: theme.colors.white,
    },
    section3ContentPosition: {
        position: 'absolute',
        top: 5,
        left: 139,
        width: 240,
        fontSize: pxToRem(12),
        lineHeight: 1.1,
    },
    headerText: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(30),
        lineHeight: pxToRem(32),
        fontWeight: 'normal',
        marginBottom: 15,
    },
    headerDescription: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(22),
        fontWeight: 'normal',
    },
    headerSubtitle: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        fontWeight: 400,
        paddingTop: 20,
    },
    sectionTitle: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(18),
        fontWeight: 400,
        marginBottom: 5,
    },
    sectionDesc: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(18),
    },
}));

const MobileView: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [isSection1Active, isSetSection1Active] = useState<boolean>(false);
    const [isSection2Active, isSetSection2Active] = useState<boolean>(false);
    const [isSection3Active, isSetSection3Active] = useState<boolean>(false);

    useHashScroll();

    return (
        <div
            data-qa="three-legged-stool"
            className={classes.root}
            id="threeleggedstool"
        >
            <div
                className={classes.headerContainer}
                style={{
                    backgroundColor: isSection1Active
                        ? colors.cyberGrape
                        : colors.gray105,
                }}
                onClick={() => {
                    isSetSection1Active(!isSection1Active);
                    customClickEvent({
                        customLinkName: `SEIU Landing: ${intl.formatMessage({
                            id: 'threeLegged.sectionOne.title',
                        })}`,
                    });
                }}
                role="presentation"
            >
                <div className={classes.contentWrapper}>
                    <h3 className={classes.headerText}>
                        <FormattedMessage
                            id="threeLegged.header"
                            defaultMessage="threeLegged.header"
                        />
                    </h3>
                    <div className={classes.headerDescription}>
                        <FormattedMessage
                            id="threeLegged.headerDesc"
                            defaultMessage="threeLegged.headerDesc"
                        />
                    </div>
                    <div className={classes.headerDescription}>
                        <FormattedMessage
                            id="threeLegged.headerDescSub"
                            defaultMessage="threeLegged.headerDescSub"
                        />
                    </div>
                    <div className={classes.headerSubtitle}>
                        <FormattedMessage
                            id="threeLegged.headerCTA"
                            defaultMessage="threeLegged.headerCTA"
                        />
                    </div>
                </div>
            </div>
            <div
                data-qa="stool-section-1"
                style={{
                    backgroundColor: isSection1Active
                        ? colors.cyberGrape
                        : colors.gray105,
                }}
            >
                <div
                    data-qa="stool-section-1-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection1Active(!isSection1Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionOne.title',
                                    defaultMessage:
                                        'threeLegged.sectionOne.title',
                                },
                            )}_1`,
                        });
                    }}
                    style={{
                        backgroundImage: `url(${
                            isSection1Active ? stoolTopOn : stoolTopOff
                        })`,
                    }}
                    className={classes.section1}
                >
                    <img className={classes.stoolLine1} src={stool1} alt="" />
                    <div className={classes.section1Content}>
                        <div className={classes.section1ContentPosition}>
                            <h3 className={classes.sectionTitle}>
                                <FormattedMessage
                                    id="threeLegged.sectionOne.title"
                                    defaultMessage="threeLegged.sectionOne.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection1Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionOne.desc"
                                    defaultMessage="threeLegged.sectionOne.desc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                data-qa="stool-section-2"
                style={{
                    backgroundColor: isSection2Active
                        ? colors.cyberGrapeDark
                        : colors.gray105,
                }}
            >
                <div
                    data-qa="stool-section-2-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection2Active(!isSection2Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionTwo.title',
                                    defaultMessage:
                                        'threeLegged.sectionTwo.title',
                                },
                            )}_2`,
                        });
                    }}
                    style={{
                        backgroundImage: `url(${
                            isSection2Active ? stoolMiddleOn : stoolMiddleOff
                        })`,
                    }}
                    className={classes.section2}
                >
                    <img className={classes.stoolLine2} src={stool2} alt="" />
                    <div className={classes.section2Content}>
                        <div className={classes.section2ContentPosition}>
                            <h3 className={classes.sectionTitle}>
                                <FormattedMessage
                                    id="threeLegged.sectionTwo.title"
                                    defaultMessage="threeLegged.sectionTwo.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection2Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionTwo.desc"
                                    defaultMessage="threeLegged.sectionTwo.desc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                data-qa="stool-section-3"
                style={{
                    backgroundColor: isSection3Active
                        ? colors.cyberGrapeDarker
                        : colors.gray105,
                }}
            >
                <div
                    data-qa="stool-section-3-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection3Active(!isSection3Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionThree.title',
                                    defaultMessage:
                                        'threeLegged.sectionThree.title',
                                },
                            )}_3`,
                        });
                    }}
                    className={classes.section3}
                >
                    <img className={classes.stoolLine3} src={stool3} alt="" />
                    <img
                        className={classes.stoolBottom}
                        src={isSection3Active ? stoolBottomOn : stoolBottomOff}
                        alt=""
                    />
                    <div className={classes.section3Content}>
                        <div className={classes.section3ContentPosition}>
                            <h3 className={classes.sectionTitle}>
                                <FormattedMessage
                                    id="threeLegged.sectionThree.title"
                                    defaultMessage="threeLegged.sectionThree.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection3Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionThree.desc"
                                    defaultMessage="threeLegged.sectionThree.desc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileView;
