import React, { FC, useEffect, useState } from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { shallowEqual, useSelector, useStore } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import flat from 'flat';
import { getNavigatorLocale } from 'utils/locales';
import queryString from 'query-string';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { LDClient } from 'launchdarkly-js-client-sdk';
import loadable from '@loadable/component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import { datadogLogs } from '@datadog/browser-logs';
// import { datadogRum } from '@datadog/browser-rum';
import { isUsEn } from 'utils';

import messagesEnUS from '../../locales/en-US.json';
//import messagesEsUS from '../../locales/es-US.json';

/* actions */
import { setSegment, setIsReturnVisit } from 'actions/user';

/* material */
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/core/styles';

/* components */
import {
	ErrorModal,
	Footer,
	Loader,
	LoaderAsync,
	Menu,
	ScrollToTop,
} from 'components';
import Routes from 'routes';

/* store */
import { RootState } from 'store';

/* theme */
import theme from 'theme/theme';
import { getCompany } from 'actions/company';

/* utils */
import { getCompanyUrl } from 'utils/url';

/* constants */
import { ANIMAL_TO_SEGMENT_MAPPING } from 'constants/user';

import './overrides.css';
// import ICRHispanic from 'containers/ICRHispanic/ICRHispanic';
import SEIULanding from 'containers/SEIULanding/SEIULanding';
import ICRGrammy from 'containers/ICRGrammy/ICRGrammy';
import { SEIU } from 'constants/company';
// add utc plugin to dayjs
dayjs.extend(utc);

const SentimentTab = loadable(() => import('components/sentimentTab'));
declare global {
	// TODO if possible change any for valid types/interfaces for analytics global variables
	interface Window {
		appEventData: any;
		Capgroup: any;
		Analytics: {
			trackScene7: any;
			singlePageApp: any;
		};
		ContextHub: any;
		s7viewers: any;
		ldClient: LDClient | undefined;
		_v?: string;
		eag: any;
		adobe: any;
		s7sdk: any;
		evidon: any;
		envConfig?: () => {
			AEM_BASE_URL: string;
			GOOGLE_RECAPTCHA_V2_KEY_SITE: string;
			LAUNCH_DARKLY_CLIENT_SIDE_ID: string;
			DATADOG_CLIENT_TOKEN: string;
			SPRING_PROFILES_ACTIVE: string;
		};
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

// we use npm run build for both, dev and production, so in both scenarios node_env process will be production
// but we do not need to track on local (development) or when running unit testing (test)
// if (process.env.NODE_ENV === 'production') {
//     datadogLogs.init({
//         clientToken: getGlobalConfig().DATADOG_CLIENT_TOKEN,
//         sampleRate: 100,
//         service: 'participants-outcomes',
//         version: process.env.REACT_APP_VERSION,
//         env: getGlobalConfig().SPRING_PROFILES_ACTIVE,
//     });

//     datadogRum.init({
//         applicationId: '8146197b-7ca7-4bdd-9960-87f48d062f9a',
//         clientToken: getGlobalConfig().DATADOG_CLIENT_TOKEN,
//         service: 'participants-outcomes',
//         version: process.env.REACT_APP_VERSION,
//         sampleRate: 100,
//         trackInteractions: true,
//         env: getGlobalConfig().SPRING_PROFILES_ACTIVE,
//     });
// }

type AppProps = {
	history: History;
};

const qs = queryString.parse(window.location.search);
//global variable to know the current version of the app (easier to detect cache related issues)
window._v = process.env.REACT_APP_VERSION;

const getSegment = (segment: string) => {
	let segmentQs = qs.s && qs.s.toString().toLowerCase();

	if (!segmentQs) return segment.toLowerCase();

	const segmentJ = ANIMAL_TO_SEGMENT_MAPPING['owl'];
	const segmentS = ANIMAL_TO_SEGMENT_MAPPING['elk'];
	// PO-106
	// If user comes from email with Segment=S and has taken quiz with
	// outcome=Owl, then update AEM Segment=J
	if (segmentQs === segmentS && segment === segmentJ) {
		segmentQs = segmentJ;
	}

	return segmentQs;
};

const App: FC<AppProps> = ({ history }) => {
	const [showSentiment, setShowSentiment] = useState<boolean>(false);
	const { showSentimentPollSlide, userRevisitThreshold } = useFlags();
	// const [isICRHispanic, setShowICRHispanic] = useState(false);
	const [isSEIU, setIsSEIU] = useState(false);
	const [isGrammy, setIsGrammy] = useState(false);
	const [isLatinGrammy, setIsLatinGrammy] = useState(false);
	const dispatch = useDispatch();
	const store = useStore();
	const ldClient = useLDClient();
	const { segment, language } = store.getState().user;
	const userSegment = getSegment(segment);
	const changeEvidonBannerLink = (evidonBanner: HTMLElement) => {
		const privacyLink = evidonBanner.getElementsByTagName('a');
		if (privacyLink && privacyLink.length) {
			privacyLink[0].href = '/privacy';
		}
	};

	const locale = getNavigatorLocale();

	useEffect(() => {
		const companyID = getCompanyUrl();
		window.ldClient = ldClient;
		dispatch(getCompany(companyID, language));
		dispatch(setSegment(userSegment));
	}, [dispatch, store, userSegment, ldClient, language]);

	useEffect(() => {
		const observer = new MutationObserver(function (_, me) {
			const evidonElement = document.getElementById('_evidon_banner');
			if (evidonElement) {
				changeEvidonBannerLink(evidonElement);
				me.disconnect();
				return;
			}
		});
		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});

		const seiuPath = new RegExp('/seiu775srp/?$', 'i');

		const unlisten = history.listen(({ pathname }) => {
			// setShowICRHispanic(pathname.toLowerCase().includes('/hispanic'));
			setIsGrammy(pathname.toLowerCase().includes('/grammy-major'));
			setIsLatinGrammy(pathname.toLowerCase().includes('/latin-grammy'));
			setIsSEIU(seiuPath.test(history.location.pathname));
		});

		// setShowICRHispanic(
		//     window.location.pathname.toLowerCase().includes('/hispanic'),
		// );
		setIsSEIU(seiuPath.test(history.location.pathname));
		setIsGrammy(
			window.location.pathname.toLowerCase().includes('/grammy-major'),
		);
		setIsLatinGrammy(
			window.location.pathname.toLowerCase().includes('/latin-grammy'),
		);

		return () => {
			unlisten();
			observer.disconnect();
		};
	}, [history]);

	useEffect(() => {
		const lastVisitDate = localStorage.getItem('lastVisitDate');
		const currentVisitDate = dayjs().utc();

		if (!lastVisitDate) {
			localStorage.setItem('lastVisitDate', currentVisitDate.format());
			return;
		}

		if (
			currentVisitDate >
			dayjs(lastVisitDate).utc().add(userRevisitThreshold, 'minute')
		) {
			setShowSentiment(!window.location.pathname.includes('/hispanic'));
			dispatch(setIsReturnVisit(true));
		}
	}, [dispatch, userRevisitThreshold]);

	const { company, user } = useSelector(
		(state: RootState) => ({
			company: state.company.theme,
			user: state.user,
		}),
		shallowEqual,
	);

	const isEn = isUsEn(user.language);
	const getMessages = () => {
		const messages: Record<string, string> = flat(messagesEnUS);
		return messages;

		// if (user.language === esUS) {
		//     const messages: Record<string, string> = flat(messagesEsUS);
		//     return messages;
		// } else {
		//     const messages: Record<string, string> = flat(messagesEnUS);
		//     return messages;
		// }
	};

	const renderApp = () => {
		if (isGrammy || isLatinGrammy) {
			return (
				<Box data-testid="app">
					<ICRGrammy />
					{company && <Footer company={company} user={user} />}
				</Box>
			);
		}
		// if (isICRHispanic) {
		//     return (
		//         <Box data-testid="app">
		//             <ICRHispanic />
		//             {company && <Footer company={company} user={user} />}
		//         </Box>
		//     );
		// }
		if (isSEIU) {
			return (
				<>
					<Box data-testid="app" mt={{ xs: 10, sm: 12.5 }}>
						<SEIULanding companyID={company?.companyId || SEIU} />
						{company && <Footer company={company} user={user} />}
					</Box>
				</>
			);
		}
		return (
			<>
				<Box data-testid="app" mt={{ xs: 10, sm: 12.5 }}>
					{showSentiment && showSentimentPollSlide && isEn ? (
						<SentimentTab
							isQuizTaken={!!user.animal}
							segment={user.segment}
						/>
					) : null}
					<Routes />
					{company && <Footer company={company} user={user} />}
				</Box>
			</>
		);
	};

	return (
		<IntlProvider locale={locale} messages={getMessages()}>
			<ThemeProvider theme={theme}>
				<CssBaseline>
					{company ? (
						<ConnectedRouter history={history}>
							<ScrollToTop />
							<Menu company={company} user={user} />
							{renderApp()}
						</ConnectedRouter>
					) : (
						<Loader />
					)}
					<ErrorModal />
					<LoaderAsync />
				</CssBaseline>
			</ThemeProvider>
		</IntlProvider>
	);
};

export default App;
