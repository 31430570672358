import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from '../../theme/font';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'theme/theme';
import { customClickEvent } from 'analytics';
import { heroHeight, heroWidth } from 'utils/siteWidthUtils';

interface CtaProps {
    title: string;
    mobileTitle: string;
    bgImagePath: string;
    bgMobileImagePath: string;
    bgColor?: string;
    subTitle: string;
    ctaLabel: string;
    ctaURL: string;
    accentImagepath?: string;
}

interface SplitFullWidthCTAProps {
    leftCtaProps: CtaProps;
    rightCtaProps: CtaProps;
    height?: number;
    dataQA: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        margin: '0px auto',
        minHeight: heroHeight,
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    bgImage: {
        backgroundRepeat: 'no-repeat',
        maxWidth: heroWidth,
        flex: '1 1',
    },
    container: {
        maxHeight: heroHeight / 2,
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'flex-end',
        padding: '20px',
        backgroundColor: 'rgba(0,0,0,0.1)',
        transition: 'background-color 0.2s ease-in-out',
        [theme.breakpoints.up('sm')]: {
            maxHeight: heroHeight,
            alignContent: 'space-between',
            padding: '0px 40px',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    leftContent: {
        color: theme.colors.white,
        textAlign: 'center',
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
            paddingBottom: 40,
        },
    },
    rightContent: {
        color: theme.colors.white,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: '0px',
        paddingTop: 0,
        paddingBottom: 10,
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            alignSelf: 'flex-start',
            marginLeft: 'auto',
            paddingTop: 20,
            paddingBottom: 0,
        },
    },
    textContent: {
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: 10,
        },
    },
    title: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        weight: 600,
        lineHeight: '26px',
        fontSize: pxToRem(22),
        [theme.breakpoints.up('sm')]: {
            weight: 600,
            lineHeight: '39px',
            fontSize: pxToRem(45),
        },
    },
    subTitle: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontWeight: 600,
        maxWidth: 405,
        lineHeight: '28px',
        fontSize: pxToRem(24),
    },
    rightSideAlign: {
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
        },
    },
    accentImageWrapper: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            padding: '20px 0px',
        },
    },
}));

const SplitFullWidthCTA: React.FC<SplitFullWidthCTAProps> = ({
    leftCtaProps,
    rightCtaProps,
    height = 500,
    dataQA,
}) => {
    const classes = useStyles();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div className={classes.root}>
            <div
                data-qa={dataQA}
                className={classes.bgImage}
                style={{
                    backgroundImage: `url('${
                        isMobile
                            ? leftCtaProps.bgMobileImagePath
                            : leftCtaProps.bgImagePath
                    }')`,
                    backgroundColor: leftCtaProps.bgColor
                        ? leftCtaProps.bgColor
                        : 'white',
                    backgroundPosition: isMobile ? 'top center' : 'top right',
                    backgroundSize: isMobile ? 'cover' : 'auto 500px',
                }}
            >
                <div
                    className={classes.container}
                    style={{
                        height: isMobile ? 500 : height,
                    }}
                >
                    {leftCtaProps.accentImagepath && (
                        <div className={classes.accentImageWrapper}>
                            <img
                                alt={leftCtaProps.title.toString()}
                                src={leftCtaProps.accentImagepath}
                            />
                        </div>
                    )}
                    <div className={classes.leftContent}>
                        <div className={classes.textContent}>
                            <h1 className={classes.title}>
                                {isMobile
                                    ? leftCtaProps.mobileTitle
                                    : leftCtaProps.title}
                            </h1>
                            {!isMobile && (
                                <h1 className={classes.subTitle}>
                                    {leftCtaProps.subTitle}
                                </h1>
                            )}
                        </div>
                        <Button
                            data-qa="left-split-hero-cta"
                            variant="outlined"
                            size="small"
                            to={leftCtaProps.ctaURL}
                            component={Link}
                            onClick={() => {
                                customClickEvent({
                                    customLinkName: `Fullwidth Hero: ${leftCtaProps.ctaLabel}`,
                                });
                            }}
                            style={{ minWidth: isMobile ? 200 : 250 }}
                        >
                            {leftCtaProps.ctaLabel}
                        </Button>
                    </div>
                </div>
            </div>
            <div
                data-qa={dataQA}
                className={classes.bgImage}
                style={{
                    backgroundImage: `url('${
                        isMobile
                            ? rightCtaProps.bgMobileImagePath
                            : rightCtaProps.bgImagePath
                    }')`,
                    backgroundColor: rightCtaProps.bgColor
                        ? rightCtaProps.bgColor
                        : 'white',
                    backgroundPosition: isMobile ? 'top center' : 'top left',
                    backgroundSize: isMobile ? 'cover' : 'auto 500px',
                }}
            >
                <div
                    className={classes.container}
                    style={{
                        height: isMobile ? 500 : height,
                    }}
                >
                    <div className={classes.rightContent}>
                        <div className={classes.textContent}>
                            <h1
                                className={clsx(
                                    classes.title,
                                    classes.rightSideAlign,
                                )}
                            >
                                {isMobile
                                    ? rightCtaProps.mobileTitle
                                    : rightCtaProps.title}
                            </h1>
                            {!isMobile && (
                                <h1
                                    className={clsx(
                                        classes.subTitle,
                                        classes.rightSideAlign,
                                    )}
                                >
                                    {rightCtaProps.subTitle}
                                </h1>
                            )}
                        </div>
                        <Button
                            data-qa="right-split-hero-cta"
                            variant="outlined"
                            size="small"
                            to={rightCtaProps.ctaURL}
                            component={Link}
                            onClick={() => {
                                customClickEvent({
                                    customLinkName: `Fullwidth Hero: ${rightCtaProps.ctaLabel}`,
                                });
                            }}
                            style={{ minWidth: isMobile ? 200 : 250 }}
                        >
                            {rightCtaProps.ctaLabel}
                        </Button>
                    </div>
                    {rightCtaProps.accentImagepath && (
                        <div
                            className={clsx(
                                classes.accentImageWrapper,
                                classes.rightSideAlign,
                            )}
                        >
                            <img
                                alt={rightCtaProps.title.toString()}
                                src={rightCtaProps.accentImagepath}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SplitFullWidthCTA;
