import { put, takeLatest, all, call } from 'redux-saga/effects';

import { GET_COMPANY } from 'constants/company';

/* interfaces */
import { Theme } from 'reducers';

/* constants */
import {
    CALCULATE_RETIREMENT_PROJECTION_UPDATE,
    CALCULATE_RETIREMENT_PROJECTIONS,
    GET_RETIREMENT_CONTRIBUTION_CONSTANTS,
} from 'constants/calculator';
import { GET_VIRTUAL_EVENT_ARTICLES } from 'constants/virtual-articles';
import { GET_MONEY_MINUTE_ARTICLES } from 'constants/article';
import { GENERIC } from 'constants/company';

/* actions */
import {
    GetCompanyAction,
    getCompanySuccess,
    getCompanyError,
    getCompanyResourcesSuccess,
} from 'actions';

/* sagas */
import {
    calculateRetirementProjectionUpdate,
    calculateRetirementProjections,
    getRetirementContributionConstants,
} from './calculator';
import { getVirtualEventArticles } from './virtualEvent';
import { getMoneyMinuteArticles } from './articles';

/* services */
import companyService from 'services/company';

/* utils */
import { getDomain } from 'utils/domain';

const checkDomain = (theme: Theme) => {
    const domainName = getDomain();
    const domainTags = theme?.domains || [];

    if (theme.companyId === GENERIC) return;

    const domainAllowed = domainTags.some((domainTag: string) => {
        const tag = domainTag.split('/')?.[1] || '';
        return tag.replace(/-/g, '') === domainName.toLowerCase();
    });

    if (!domainAllowed) {
        window.location.href = `/${GENERIC}/404`;
    }
};

export function* fetchCompany(params: GetCompanyAction) {
    try {
        let res = yield call(companyService.fetchCompany, params);
        let json;

        if (res.ok) {
            json = yield call([res, 'json']);
        }

        if (!res.ok || !json.count) {
            res = yield call(companyService.fetchDefaultCompany);
            if (!res.ok) throw new Error('Error fetching company');

            json = yield call([res, 'json']);
            if (!json.count) throw new Error('Company not found');
        }

        const companyTheme = json.configs[0];

        // Check if the company is allowed in the current domain
        checkDomain(companyTheme);
        yield put(getCompanySuccess(companyTheme));

        res = yield call(
            companyService.fetchCompanyResources,
            params.companyId,
        );

        if (res.ok) {
            json = yield call([res, 'json']);
            yield put(getCompanyResourcesSuccess(json.resources));
        }
    } catch (e) {
        yield put(getCompanyError(e));
    }
}

function* actionWatcher() {
    yield takeLatest(GET_COMPANY, fetchCompany);
    yield takeLatest(
        CALCULATE_RETIREMENT_PROJECTIONS,
        calculateRetirementProjections,
    );
    yield takeLatest(
        CALCULATE_RETIREMENT_PROJECTION_UPDATE,
        calculateRetirementProjectionUpdate,
    );
    yield takeLatest(
        GET_RETIREMENT_CONTRIBUTION_CONSTANTS,
        getRetirementContributionConstants,
    );
    yield takeLatest(GET_VIRTUAL_EVENT_ARTICLES, getVirtualEventArticles);
    yield takeLatest(GET_MONEY_MINUTE_ARTICLES, getMoneyMinuteArticles);
}

export default function* rootSaga() {
    yield all([actionWatcher()]);
}
