import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import stoolTopOff from 'assets/stool-top-off.svg';
import stoolMiddleOff from 'assets/stool-middle-off.svg';
import stoolBottomOff from 'assets/stool-bottom-off.svg';
import stoolTopOn from 'assets/stool-top-on.svg';
import stoolMiddleOn from 'assets/stool-middle-on.svg';
import stoolBottomOn from 'assets/stool-bottom-on.svg';
import stool1 from 'assets/stool-1.png';
import stool2 from 'assets/stool-2.png';
import stool3 from 'assets/stool-3.png';
import colors from 'theme/colors';
import { customClickEvent } from 'analytics';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import useHashScroll from 'hooks/useScrollHash';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        zoom: 0.8,
        '& > *': {
            transition: 'all 1.5s ease',
        },
    },
    headerContent: {
        textAlign: 'center',
        padding: '30px 75px 30px',
        width: 1600,
        margin: '0px auto',
        color: theme.colors.white,
    },
    section1: {
        height: 164,
        display: 'flex',
        '& > div': {
            flex: '1 1 0',
        },
        width: 1600,
        margin: '0px auto',
    },
    section1ContentPosition: {
        position: 'absolute',
        top: -15,
        left: -25,
        width: 350,
    },
    section1Content: {
        color: 'white',
        position: 'relative',
    },
    section2: {
        height: 250,
        display: 'flex',
        '& > div': {
            flex: '1 1 0',
        },
        width: 1600,
        margin: '0px auto',
    },
    section2ContentPosition: {
        position: 'absolute',
        top: -5,
        right: 30,
        width: 350,
    },
    section2Content: {
        color: theme.colors.white,
        position: 'relative',
    },
    section3: {
        height: 200,
        display: 'flex',
        '& > div': {
            flex: '1 1 0',
        },
        width: 1600,
        margin: '0px auto',
        position: 'relative',
        top: -1,
        left: '0.3px',
    },
    section3ContentPosition: {
        position: 'absolute',
        top: -4,
        left: -15,
        width: 350,
    },
    section3Content: {
        color: theme.colors.white,
        position: 'relative',
    },
    stoolBG: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        position: 'relative',
    },
    stoolLine1: {
        position: 'absolute',
        top: 5,
        right: 40,
    },
    stoolLine2: {
        position: 'absolute',
        top: 15,
        left: -220,
    },
    stoolLine3: {
        position: 'absolute',
        top: 15,
        left: 280,
    },
    section3bottomPadding: {
        height: 50,
    },
    headerText: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(35),
        lineHeight: pxToRem(46),
        fontWeight: 'normal',
    },
    headerDescription: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(20),
        lineHeight: pxToRem(30),
        fontWeight: 'normal',
    },
    headerSubtitle: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(22),
        fontWeight: 400,
        paddingTop: 40,
    },
    sectionTitle: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(26),
        fontWeight: 400,
        marginBottom: 10,
    },
    sectionDesc: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(26),
        width: 450,
    },
}));

const DesktopView: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [isSection1Active, isSetSection1Active] = useState<boolean>(false);
    const [lineOneStyle, setLineOneStyle] = useState<object>({});
    const [isSection2Active, isSetSection2Active] = useState<boolean>(false);
    const [lineTwoStyle, setLineTwoStyle] = useState<object>({});
    const [isSection3Active, isSetSection3Active] = useState<boolean>(false);
    const [lineThreeStyle, setLineThreeStyle] = useState<object>({});

    const hoverStyle = {
        filter: `drop-shadow(3px 3px 3px ${colors.black80}`,
        WebkitFilter: `drop-shadow(3px 3px 3px ${colors.black80}`,
    };

    useHashScroll();
    return (
        <div
            data-qa="three-legged-stool"
            className={classes.root}
            id="threeleggedstool"
        >
            <div
                data-qa="stool-section-1"
                style={{
                    backgroundColor: isSection1Active
                        ? colors.cyberGrape
                        : colors.gray105,
                }}
            >
                <div
                    onClick={() => {
                        isSetSection1Active(!isSection1Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionOne.title',
                                },
                            )}`,
                        });
                    }}
                    onMouseEnter={() => {
                        setLineOneStyle(hoverStyle);
                    }}
                    onMouseLeave={() => {
                        setLineOneStyle({});
                    }}
                    role="presentation"
                    className={classes.headerContent}
                >
                    <h2 className={classes.headerText}>
                        <FormattedMessage
                            id="threeLegged.header"
                            defaultMessage="threeLegged.header"
                        />
                    </h2>
                    <div className={classes.headerDescription}>
                        <FormattedMessage
                            id="threeLegged.headerDesc"
                            defaultMessage="threeLegged.headerDesc"
                        />
                    </div>
                    <div className={classes.headerDescription}>
                        <FormattedMessage
                            id="threeLegged.headerDescSub"
                            defaultMessage="threeLegged.headerDescSub"
                        />
                    </div>
                    <div className={classes.headerSubtitle}>
                        <FormattedMessage
                            id="threeLegged.headerCTA"
                            defaultMessage="threeLegged.headerCTA"
                        />
                    </div>
                </div>
                <div
                    data-qa="stool-section-1-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection1Active(!isSection1Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionOne.title',
                                    defaultMessage:
                                        'threeLegged.sectionOne.title',
                                },
                            )}_1`,
                        });
                    }}
                    onMouseEnter={() => {
                        setLineOneStyle(hoverStyle);
                    }}
                    onMouseLeave={() => {
                        setLineOneStyle({});
                    }}
                    className={classes.section1}
                >
                    <div />
                    <div
                        className={classes.stoolBG}
                        style={{
                            backgroundImage: `url(${
                                isSection1Active ? stoolTopOn : stoolTopOff
                            })`,
                        }}
                    >
                        <img
                            className={classes.stoolLine1}
                            src={stool1}
                            alt=""
                            style={!isSection1Active ? lineOneStyle : {}}
                        />
                    </div>
                    <div className={classes.section1Content}>
                        <div className={classes.section1ContentPosition}>
                            <h3
                                style={!isSection1Active ? lineOneStyle : {}}
                                className={classes.sectionTitle}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionOne.title"
                                    defaultMessage="threeLegged.sectionOne.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection1Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionOne.desc"
                                    defaultMessage="threeLegged.sectionOne.desc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                data-qa="stool-section-2"
                style={{
                    backgroundColor: isSection2Active
                        ? colors.cyberGrapeDark
                        : colors.gray105,
                }}
            >
                <div
                    data-qa="stool-section-2-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection2Active(!isSection2Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged: ${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionTwo.title',
                                    defaultMessage:
                                        'threeLegged.sectionTwo.title',
                                },
                            )}_2`,
                        });
                    }}
                    onMouseEnter={() => {
                        setLineTwoStyle(hoverStyle);
                    }}
                    onMouseLeave={() => {
                        setLineTwoStyle({});
                    }}
                    className={classes.section2}
                >
                    <div className={classes.section2Content}>
                        <div className={classes.section2ContentPosition}>
                            <h3
                                style={!isSection2Active ? lineTwoStyle : {}}
                                className={classes.sectionTitle}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionTwo.title"
                                    defaultMessage="threeLegged.sectionTwo.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection2Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionTwo.desc"
                                    defaultMessage="threeLegged.sectionTwo.desc"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={classes.stoolBG}
                        style={{
                            backgroundImage: `url(${
                                isSection2Active
                                    ? stoolMiddleOn
                                    : stoolMiddleOff
                            })`,
                        }}
                    >
                        <img
                            className={classes.stoolLine2}
                            src={stool2}
                            alt=""
                            style={!isSection2Active ? lineTwoStyle : {}}
                        />
                    </div>
                    <div />
                </div>
            </div>
            <div
                data-qa="stool-section-3"
                style={{
                    backgroundColor: isSection3Active
                        ? colors.cyberGrapeDarker
                        : colors.gray105,
                }}
            >
                <div
                    data-qa="stool-section-3-clickable"
                    role="presentation"
                    onClick={() => {
                        isSetSection3Active(!isSection3Active);
                        customClickEvent({
                            customLinkName: `SEIU Landing three legged_${intl.formatMessage(
                                {
                                    id: 'threeLegged.sectionThree.title',
                                    defaultMessage:
                                        'threeLegged.sectionThree.title',
                                },
                            )}_3`,
                        });
                    }}
                    onMouseEnter={() => {
                        setLineThreeStyle(hoverStyle);
                    }}
                    onMouseLeave={() => {
                        setLineThreeStyle({});
                    }}
                    className={classes.section3}
                >
                    <div />
                    <div
                        className={classes.stoolBG}
                        style={{
                            backgroundImage: `url(${
                                isSection3Active
                                    ? stoolBottomOn
                                    : stoolBottomOff
                            })`,
                        }}
                    >
                        <img
                            className={classes.stoolLine3}
                            src={stool3}
                            alt=""
                            style={!isSection3Active ? lineThreeStyle : {}}
                        />
                    </div>
                    <div className={classes.section3Content}>
                        <div className={classes.section3ContentPosition}>
                            <h3
                                style={!isSection3Active ? lineThreeStyle : {}}
                                className={classes.sectionTitle}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionThree.title"
                                    defaultMessage="threeLegged.sectionThree.title"
                                />
                            </h3>
                            <div
                                style={{
                                    visibility: isSection3Active
                                        ? 'visible'
                                        : 'hidden',
                                }}
                                className={classes.sectionDesc}
                            >
                                <FormattedMessage
                                    id="threeLegged.sectionThree.desc"
                                    defaultMessage="threeLegged.sectionThree.desc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.section3bottomPadding}></div>
            </div>
        </div>
    );
};

export default DesktopView;
