import React, { FC, useEffect } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { pageViewCompleteEvent } from 'analytics';
import { makeStyles } from '@material-ui/core/styles';
import { setError } from 'actions/error';

import HeroCarousel from './components/HeroCarousel/HeroCarousel';
import ThreeLeggedStool from './components/ThreeLeggedStool/ThreeLeggedStool';
import RegisterCTA from './components/RegisterCTA';
import LearnAboutIRA from './components/LearnAboutIRA/LearnAboutIRA';

import { safelyParseJson } from 'utils/parseJson';
import useAEMBaseUrl from 'hooks/useAEMBaseUrl';
import { useFetch } from 'hooks/useFetch';
import { Loader } from 'components';
import flat from 'flat';
import { useDispatch } from 'react-redux';
// import { AlertBar } from 'components/alertBar/AlertBar';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 80,
        position: 'relative',
        minHeight: 300,
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginTop: 100,
        },
    },
}));

type SEIULandingTypes = {
    companyID?: string;
};

const SEIULanding: FC<SEIULandingTypes> = () => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        pageViewCompleteEvent();
    }, []);

    const {
        data: seiuLandingData,
        loading: isSeiuLandingDataLoading,
        error: isSeiuContentDataLoadingError,
    } = useFetch<{ [key: string]: any } | undefined>({
        initialData: undefined,
        processData: (response: { data: string }) =>
            safelyParseJson(response.data),
        url: useAEMBaseUrl(
            `/content/fragments?path=/content/dam/my-retirement/language/seiu-landing/jcr:content/data/master.json`,
        ),
    });

    useEffect(() => {
        if (isSeiuContentDataLoadingError) {
            dispatch(setError(new Error('error fetching SEIU content')));
        }
    }, [dispatch, isSeiuContentDataLoadingError]);

    if (isSeiuLandingDataLoading) {
        return (
            <div className={classes.root}>
                <Loader />
            </div>
        );
    }

    if (isSeiuContentDataLoadingError) {
        return null;
    }
    const messages: Record<string, string> = flat(seiuLandingData?.en);
    const heroVideoConfig =
        seiuLandingData?.en.landingHero.welcomeVideo.videoConfig || null;

    return (
        <div data-qa="seiu-landing">
            <IntlProvider locale="en-US" messages={messages}>
                <Helmet>
                    <title>
                        {intl.formatMessage({
                            id: 'meta.home.title',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={intl.formatMessage({
                            id: 'meta.home.desc',
                        })}
                    />
                </Helmet>
                <div className={classes.root}>
                    {/* <AlertBar
                        message="It’s Hispanic Heritage Month. Celebrate with our new cultural experience."
                        ctaLabel="CELEBRATE"
                        ctaURL={`/${companyID}/hispanic`}
                        dataQA="hp-hero-hispanic-button"
                    /> */}
                    <HeroCarousel videoConfig={heroVideoConfig} />
                    <ThreeLeggedStool />
                    <LearnAboutIRA />
                    <RegisterCTA />
                </div>
            </IntlProvider>
        </div>
    );
};

export default SEIULanding;
