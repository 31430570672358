const CONSTANTS = {
    PRIVACY_PATH: 'privacy',
    ARTICLES_PATH: 'articles',
    ARTICLES: 'Articles',
    PRIVACY: 'Privacy',
    HOME: 'Home',
    GENERIC_COMPANY: 'generic',
    ICANRETIRE: 'i-can-retire',
    MYRETIREMENT: 'my-retirement',
    US: 'us',
    EN: 'en',
    QUIZ_PATH: '/quiz/',
    QUIZ_RESULTS_PATH: 'quiz-result',
};

const isArticle = (path: string) => {
    return path.indexOf(`/${CONSTANTS.ARTICLES_PATH}/`) > -1;
};

const getPageName = (path: string, routeName: string, domain: string) => {
    if (isArticle(path)) {
        return `${CONSTANTS.ARTICLES} > ${routeName}`;
    } else {
        const pathArray = path.split('/');
        if (pathArray.length > 3) {
            return `${pathArray[2]} > ${pathArray[pathArray.length - 1]}`;
        } else {
            return `${domain} > ${routeName}`;
        }
    }
};

const getAnalyticsPageProperties = (path: string) => {
    let pageName = '';
    let siteSection1 = '';
    let siteSection2 = '';
    let siteSection3 = '';
    let routeName = '';
    let audience = 'generic';

    const domain =
        window.location.host.indexOf('myretirement') > -1
            ? CONSTANTS.MYRETIREMENT
            : CONSTANTS.ICANRETIRE;
    const channel =
        domain === CONSTANTS.MYRETIREMENT ? 'myretirement' : 'icanretire';
    const currentPath = path.length > 1 ? path.replace(/\/$/, '') : path;
    const pathSlashCount = (currentPath.match(/\//g) || []).length;
    const getLastDirectoryPath = currentPath.match(/[^/]+(?=\/$|$)/);
    const pathArray = currentPath.split('/');

    routeName = (getLastDirectoryPath && getLastDirectoryPath[0]) || '';

    if (pathSlashCount === 1) {
        // Identify if the route is a company aka Home or the privacy page.
        if (path.indexOf(CONSTANTS.PRIVACY_PATH) > -1) {
            siteSection1 = CONSTANTS.PRIVACY;
        } else {
            siteSection1 = CONSTANTS.HOME;
            routeName = CONSTANTS.HOME;
        }
        if (pathArray[1]) {
            audience = pathArray[1];
        }
    } else {
        audience = pathArray[1];
        if (isArticle(path)) {
            siteSection1 = CONSTANTS.ARTICLES;
            siteSection2 = routeName || '';
        } else {
            if (pathArray.length === 3) {
                siteSection1 = pathArray[2];
            }

            if (pathArray.length === 4) {
                siteSection1 = pathArray[2];
                siteSection2 = pathArray[3];
            }

            if (pathArray.length === 5) {
                siteSection1 = pathArray[2];
                siteSection2 = pathArray[3];
                siteSection3 = pathArray[4];
            }
        }
    }

    pageName = getPageName(currentPath, routeName, domain);

    //default to last route name if section is not set
    siteSection1 = siteSection1 ? siteSection1 : routeName || '';

    return {
        pageName: pageName,
        siteSection1: isArticle(path) ? CONSTANTS.ARTICLES : siteSection1,
        siteSection2: siteSection2,
        siteSection3: siteSection3,
        routeName: routeName,
        audience: audience,
        channel: channel,
    };
};

export const pageViewEvent = (
    data = {
        path: '',
        personaSegment: '',
        uid: '',
        language: '',
    },
) => {
    const {
        pageName,
        siteSection1,
        siteSection2,
        audience,
        channel,
        siteSection3,
    } = getAnalyticsPageProperties(data.path);

    window.appEventData = window.appEventData || [];

    console.log('Analytics Page view start - baseURL:', (window.location.origin + data.path).toString());
    console.log('Analytics Page view start - channel:', channel);
    console.log('Analytics Page view start - pageName:', pageName);
    console.log('Analytics Page view start - siteAudience:', audience);
    console.log('Analytics Page view start - siteCountry:', CONSTANTS.US);
    console.log('Analytics Page view start - siteLanguage:', data.language);
    console.log('Analytics Page view start - siteSection1:', siteSection1);
    console.log('Analytics Page view start - siteSection2:', siteSection2);
    console.log('Analytics Page view start - siteSection3:', siteSection3);
    console.log('Analytics Page view start - personaSegment:', data.personaSegment);

    window.appEventData.push({
        event: 'Page Load Started',
        page: {
            baseURL: (window.location.origin + data.path).toString(),
            channel: channel,
            navigationPath: '',
            pageName: pageName,
            siteAudience: audience,
            siteCountry: CONSTANTS.US,
            siteLanguage: data.language,
            siteSection1: siteSection1,
            siteSection2: siteSection2,
            siteSection3: siteSection3,
            siteSection4: '',
            webPageType: '',
        },
        user: {
            advisorId: '',
            dealerCode: '',
            institutionalID: '',
            investorId: '',
            partyId: '',
            personaSegment: data.personaSegment,
            planId: '',
            planParticipantId: '',
            tracSponsorId: '',
            userRole: '',
            visionId: '',
        },
    });
};
