import React, { FC, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from 'react-share';

/* material */
import { Theme } from '@material-ui/core/';
import { Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Email as EmailIcon,
    Facebook as FacebookIcon,
    Link as LinkIcon,
    Twitter as TwitterIcon,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

/* utils */
import colors from 'theme/colors';
import { copyToClipboard } from 'utils/menuHelpers';
import { AVENIR_NEXT_LT_DEMI } from 'theme/font';

/* constants */
import { GENERIC_ARTICLE_PATHNAME } from 'constants/article';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles({
    icon: {
        color: colors.symphonyBlue,
        cursor: 'pointer',
        fontSize: 19,
    },
    button: {
        alignItems: 'flex-start',
        display: 'flex',
        padding: 0,
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: colors.white,
        border: `solid 1px ${colors.symphonyBlue50}`,
        borderRadius: '2px',
        boxShadow: theme.shadows[3],
        color: colors.symphonyBlue,
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontSize: 13,
        padding: '8px 12px 6px 12px',
    },
    arrow: {
        color: colors.symphonyBlue50,
        fontSize: '16px',

        '&::after': {
            content: '""',
            display: 'block',
            borderRight: '8px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: `8px solid #fff`,
            height: '0',
            left: '0',
            position: 'absolute',
            top: '5px',
            width: '0',
        },
    },
}))(Tooltip);

export const getGenericShareUrl = (articleId: string) => {
    const { protocol, host, pathname } = window.location;
    const articleName = pathname.substring(pathname.lastIndexOf('/') + 1);

    return `${protocol}//${host}${GENERIC_ARTICLE_PATHNAME}${articleId}/${articleName}`;
};

type ShareProps = {
    articleId: string;
    articleTitle: string;
    domainName: string;
};

const Share: FC<ShareProps> = ({ articleId, articleTitle, domainName }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const [tooltipText, setTooltipText] = useState<JSX.Element | string>('');
    const timeout = useRef<ReturnType<typeof setTimeout> | null>();

    const classes = useStyles();
    const intl = useIntl();
    const url = getGenericShareUrl(articleId);
    const hasClipboardSupport = !!(navigator.clipboard || document.execCommand);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    const handleLinkCopyClick = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const copySuccess = await copyToClipboard(event, url);
        const copySuccessText = copySuccess ? (
            <FormattedMessage id="share.copied" />
        ) : (
            <FormattedMessage id="share.copiedFailed" />
        );
        setTooltipText(copySuccessText);
        setIsTooltipOpen(true);
        setTooltipCloseTimer();
    };

    const setTooltipCloseTimer = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        timeout.current = setTimeout(() => setIsTooltipOpen(false), 3000);
    };

    return (
        <Box width="128px" display="flex" justifyContent="space-between">
            <TwitterShareButton
                data-share-channel="twitter"
                data-share-title={articleTitle}
                data-qa="share-twitter-icon"
                url={url}
            >
                <TwitterIcon className={classes.icon} />
            </TwitterShareButton>
            <FacebookShareButton
                data-share-channel="facebook"
                data-share-title={articleTitle}
                data-qa="share-facebook-icon"
                url={url}
            >
                <FacebookIcon className={classes.icon} />
            </FacebookShareButton>
            <EmailShareButton
                subject={intl.formatMessage(
                    {
                        id: 'articleLanding.emailShareSubject',
                    },
                    { domainName },
                )}
                data-share-channel="email"
                data-share-title={articleTitle}
                data-qa="share-email-icon"
                url={url}
            >
                <EmailIcon className={classes.icon} />
            </EmailShareButton>
            {hasClipboardSupport && (
                <LightTooltip
                    disableFocusListener
                    title={tooltipText}
                    open={isTooltipOpen}
                    placement={isMobile ? 'bottom-end' : 'bottom'}
                    arrow
                >
                    <IconButton
                        className={classes.button}
                        data-qa="share-clipboard-icon"
                        data-clipboard-text={url}
                        onClick={(e) => {
                            handleLinkCopyClick(e);
                            customClickEvent({
                                customLinkName: `share: link copied`,
                            });
                        }}
                        aria-label={intl.formatMessage({
                            id: 'share.articleLink',
                        })}
                    >
                        <LinkIcon className={classes.icon} />
                    </IconButton>
                </LightTooltip>
            )}
        </Box>
    );
};

export default Share;
