/* constants */
import {
    GET_COMPANY,
    GET_COMPANY_ERROR,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_RESOURCES_SUCCESS,
} from 'constants/company';

/* types */
import { CompanyActionTypes } from 'actions/company';
import { resourceType } from 'containers/MakeAChange/types';

/* utils */
import { ErrorMap, errorMap } from 'utils';

export type Theme = {
    autoIncreasePercentage: number;
    clientIntranetSiteLabel: string;
    clientIntranetSiteUrl: string;
    companyId: string;
    companyLogo: string;
    companyName: string;
    contactUsEmail: string;
    enableEmailMyActionPlan: boolean;
    planDetailsUrl: string;
    recordKeeperUrl: string;
    rkIncreaseContributionUrl: string;
    showCompanyResourcesLink: boolean;
    showContributionFields: boolean;
    showPlanDetails: boolean;
    recordKeeperName: string;
    recordKeeperPhoneNumber: string;
    recordKeeperAllocationUrl: string;
    contactUsEmailFrom: string;
    my401kContent: string;
    showContactUs: boolean;
    showTargetDate: boolean;
    enrollUrl: string;
    domains: string[];
    leavingIcrModalTextOverride: string;
    changeContributionCtaTextOverride?: string;
    tags: string[];
    employerMatchingPercentage: number;
    employerContributionPercentage: number;
    employerMatchingPolicy: string;
    employerMatchingPolicyEspanol: string;
};

/* store state */
export type Company = {
    error: ErrorMap | null;
    loading: boolean;
    theme: Theme | null;
    resources: resourceType[];
};

export const initialState: Company = {
    error: null,
    loading: false,
    theme: null,
    resources: [],
};

export const companyReducer = (
    state = initialState,
    action: CompanyActionTypes,
): Company => {
    switch (action.type) {
        case GET_COMPANY:
            return { ...state, loading: true };
        case GET_COMPANY_SUCCESS:
            const tag = action.config.tags.find((t) =>
                t.includes(':companies/'),
            );
            if (tag) {
                const companyId = tag.substring(
                    tag.lastIndexOf('/') + 1,
                    tag.length,
                );
                action.config.companyId = companyId;
            }
            return {
                ...state,
                loading: false,
                error: null,
                theme: action.config,
            };
        case GET_COMPANY_ERROR:
            return { ...state, error: errorMap(action.error), loading: false };
        case GET_COMPANY_RESOURCES_SUCCESS:
            return {
                ...state,
                resources: action.resources,
            };
        default:
            return state;
    }
};
