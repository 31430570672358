import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

/* material */
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/* utils */
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';
import { heroHeight, heroWidth, siteWidth } from 'utils/siteWidthUtils';

const useStyles = makeStyles((theme) => ({
    root: (props: Pick<BaseHeroProps, 'bgColor' | 'isHeroHeight'>) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        height: props.isHeroHeight ? heroHeight : 'auto',
        backgroundColor: props.bgColor ?? '#fffaf4',
        [theme.breakpoints.up('sm')]: {
            overflow: 'hidden',
            flexDirection: 'row',
            backgroundColor: 'transparent',
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: '16%',
                zIndex: -1,
                backgroundColor: props.bgColor ?? '#fffaf4',
            },
        },
    }),
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: heroWidth,
        margin: theme.spacing(2.5, 2.5, 0),
        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start',
            margin: theme.spacing(0, 5),
            maxWidth: siteWidth,
        },
        '& .MuiTypography-root': {
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'left',
            },
        },
        '& .baseHeroCta-eyebrow': {
            fontFamily: AVENIR_NEXT_LT_DEMI,
            fontWeight: 700,
            fontSize: pxToRem(14),
            lineHeight: pxToRem(18),
            letterSpacing: 1.5,
            color: '#b42573',
            marginBottom: theme.spacing(2.5),
            textTransform: 'uppercase',
        },
        '& .baseHeroCta-boldText': {
            fontFamily: AVENIR_NEXT_LT_DEMI,
            fontSize: pxToRem(20),
            lineHeight: pxToRem(28),
            marginBottom: theme.spacing(2.5),
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(30),
                lineHeight: pxToRem(38),
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: pxToRem(45),
                lineHeight: pxToRem(50),
            },
        },
        '& .baseHeroCta-text': {
            fontFamily: AVENIR_NEXT_LT_REGULAR,
            fontSize: pxToRem(16),
            lineHeight: pxToRem(24),
            '& strong': { fontFamily: AVENIR_NEXT_LT_DEMI },
            [theme.breakpoints.up('sm')]: {
                fontSize: pxToRem(22),
                lineHeight: pxToRem(28),
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: pxToRem(24),
                lineHeight: pxToRem(32),
            },
        },
    },
    image: {
        width: 343,
        [theme.breakpoints.up('sm')]: {
            minWidth: 343,
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 440,
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 590,
        },
        '& img': {
            width: '100%',
        },
    },
    button: {
        display: 'inline-block',
        margin: theme.spacing(2.5, 0),
        width: 'auto',
    },
}));
export type BaseHeroProps = {
    bgColor?: string;
    boldText: string;
    className?: string;
    ctaProps?: object;
    ctaText: string;
    ctaUrl: string;
    customLink: string;
    dataQA: string;
    eyebrowText?: string;
    isHeroHeight?: boolean;
    imageAlt: string;
    imagePath: string;
    imagePathDesktop?: string;
    text: string;
};

const BaseHero: FC<BaseHeroProps> = ({
    bgColor,
    boldText,
    className,
    ctaProps = {},
    ctaText,
    ctaUrl,
    customLink,
    dataQA,
    eyebrowText,
    isHeroHeight,
    imageAlt,
    imagePath,
    imagePathDesktop,
    text,
}) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { root, button, content, image } = useStyles({
        bgColor,
        isHeroHeight,
    });
    return (
        <div
            className={clsx(
                root,
                className,
                'baseHeroCta-wrapper',
                `${dataQA}-baseHeroCta-wrapper`,
            )}
            data-qa={dataQA}
        >
            <div
                className={clsx(
                    image,
                    'baseHeroCta-image',
                    `${dataQA}-baseHeroCta-image`,
                )}
                data-qa={`${dataQA}-image`}
            >
                <img
                    src={
                        isDesktop && imagePathDesktop
                            ? imagePathDesktop
                            : imagePath
                    }
                    alt={imageAlt}
                />
            </div>
            <div
                className={clsx(
                    content,
                    'baseHeroCta-content',
                    `${dataQA}-baseHeroCta-content`,
                )}
            >
                {eyebrowText && (
                    <Typography
                        data-qa={`${dataQA}-eyebrow`}
                        className="baseHeroCta-eyebrow"
                        paragraph
                        variant="h3"
                    >
                        {eyebrowText}
                    </Typography>
                )}
                <Typography
                    data-qa={`${dataQA}-boldText`}
                    className={clsx(
                        'baseHeroCta-boldText',
                        `${dataQA}-baseHeroCta-boldText`,
                    )}
                    component="h3"
                    variant="h3"
                    dangerouslySetInnerHTML={{ __html: boldText }}
                />
                <Typography
                    data-qa={`${dataQA}-text`}
                    className={clsx(
                        'baseHeroCta-text',
                        `${dataQA}-baseHeroCta-text`,
                    )}
                    paragraph
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <Button
                    data-qa={`${dataQA}-cta`}
                    onClick={() => {
                        customClickEvent({
                            customLinkName: `${customLink}`,
                        });
                    }}
                    className={clsx(
                        button,
                        'baseHeroCta-button',
                        `${dataQA}-baseHeroCta-button`,
                    )}
                    component={Link}
                    to={ctaUrl}
                    variant="outlined"
                    {...ctaProps}
                >
                    {ctaText}
                </Button>
            </div>
        </div>
    );
};

export default BaseHero;
