import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { asyncLoad } from 'components';

// import { ANIMAL_TO_SEGMENT_MAPPING } from 'constants/user';
import ICRHispanic from 'containers/ICRHispanic/ICRHispanic';

/* Import the container pages, just pass the Folder name as param to load container */
const ArticleLanding = asyncLoad.container('article-landing/ArticleLanding');
const CalculatorDisclosures = asyncLoad.container(
    'calculator/CalculatorDisclosures',
);
const CalculatorLanding = asyncLoad.container('calculator/CalculatorLanding');
const Error = asyncLoad.container('Error/Error');
const HomePageLanding = asyncLoad.container('HomePage/HomePageLanding');
const GetTheFacts = asyncLoad.container('GetTheFacts/GetTheFacts');
const MakeAChange = asyncLoad.container('MakeAChange/MakeAChange');
const FAQs = asyncLoad.container('FAQs/FAQs');
const NotFound = asyncLoad.container('404/404');
const PrivacyPolicy = asyncLoad.container('PrivacyPolicy/PrivacyPolicy');
const Projections = asyncLoad.container('projections/Projections');
// const Quiz = asyncLoad.container('PersonaQuiz/Quiz');
// const QuizLanding = asyncLoad.container('PersonaQuiz/QuizLanding');
// const QuizResult = asyncLoad.container('PersonaQuiz/QuizResult');
const ContactUs = asyncLoad.container('ContactUs/ContactUs');
const RwjbhWorkshop = asyncLoad.container('RwjbhWorkshop/RwjbhWorkshop');
const SiteMap = asyncLoad.container('SiteMap/SiteMap');

// const animalRoutes = Object.keys(ANIMAL_TO_SEGMENT_MAPPING).join('|');
// const quizResultAnimalRoute = `/:company/quiz-result/:animal(${animalRoutes})`;
const Unsubscribe = asyncLoad.container('Unsubscribe/Unsubscribe');
const VirtualEvent = asyncLoad.container('VirtualEvent/VirtualEvent');
const VirtualEventVideoLanding = asyncLoad.container(
    'VirtualEvent/VirtualEventVideoLanding',
);
const DollarOnRoadTrip = asyncLoad.container(
    'DollarOnRoadTrip/DollarOnRoadTrip',
);
const MoneyMinuteArticleLanding = asyncLoad.container(
    'MoneyMinute/MoneyMinuteArticleLanding',
);
const TargetDateLanding = asyncLoad.container('TargetDate/TargetDateLanding');
const TransitioningToRetirementLanding = asyncLoad.container(
    'TransitioningToRetirement/TransitioningToRetirementLanding',
);

const Routes = () => {
    return (
        <Fragment>
            <Switch>
                <Route exact path="/" component={HomePageLanding} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                {/* <Route exact path="/:company/quiz/:step" component={Quiz} /> */}
                {/* <Route exact path="/:company/quiz" component={QuizLanding} />
                <Route
                    exact
                    path="/:company/quiz-result"
                    component={QuizResult}
                />
                <Route
                    exact
                    path={quizResultAnimalRoute}
                    component={QuizResult}
                /> */}
                <Route path="/:company/get-the-facts" component={GetTheFacts} />
                <Route
                    exact
                    path="/:company/calculator"
                    component={CalculatorLanding}
                />
                <Route
                    exact
                    path="/:company/calculator/disclosures"
                    component={CalculatorDisclosures}
                />
                <Route
                    exact
                    path="/:company/calculator/projections"
                    component={Projections}
                />
                <Route
                    exact
                    path="/:company/make-a-change"
                    component={MakeAChange}
                />
                <Route exact path="/:company/faqs" component={FAQs} />
                <Route exact path="/:company/error" component={Error} />
                <Route
                    exact
                    path="/:company/articles/:articleId/:article"
                    component={ArticleLanding}
                />
                <Route
                    exact
                    path="/:company/tools/:article"
                    component={ArticleLanding}
                />
                <Route exact path="/:company" component={HomePageLanding} />
                <Route
                    exact
                    path="/:company/contact-us"
                    component={ContactUs}
                />
                <Route path="/:company/unsubscribe" component={Unsubscribe} />
                <Route
                    exact
                    path="/:company/virtual-event/articles/:articleId/:article"
                    component={VirtualEventVideoLanding}
                />
                <Route
                    exact
                    path="/:company/money-minute/articles/:articleId/:article"
                    component={MoneyMinuteArticleLanding}
                />
                <Route
                    exact
                    path="/:company/roadtrip"
                    component={DollarOnRoadTrip}
                />
                <Route
                    exact
                    path="/:company/virtual-event"
                    component={VirtualEvent}
                />
                <Route
                    exact
                    path="/:company/target-date/articles/:articleId/:article"
                    component={MoneyMinuteArticleLanding}
                />
                <Route
                    exact
                    path="/:company/target-date"
                    component={TargetDateLanding}
                />
                <Route
                    exact
                    path="/:company/workshop"
                    component={RwjbhWorkshop}
                />
                <Route
                    exact
                    path="/:company/transitioning-to-retirement/:filter"
                    component={TransitioningToRetirementLanding}
                />
                <Route exact path="/:company/sitemap" component={SiteMap} />
                <Route
                    exact
                    path="/:company/hispanic"
                    component={ICRHispanic}
                />
                <Route component={NotFound} />
            </Switch>
        </Fragment>
    );
};

export default Routes;
