import * as React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import seiuQuizHero from 'assets/seiuQuizHero.svg';
import { pxToRem } from 'theme/util';
import { customClickEvent } from 'analytics';
import { heroHeight, heroWidth } from 'utils/siteWidthUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px auto',
        height: heroHeight,
        maxWidth: heroWidth,
        backgroundColor: '#71C9CE',
        [theme.breakpoints.up('sm')]: {},
    },
    heroBg: {
        textAlign: 'center',
        width: '100%',
        height: '500px',
        backgroundImage: `url(${seiuQuizHero})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom left',
        backgroundColor: 'rgba(39, 39, 39, 0.6);',
        backgroundBlendMode: 'multiply',
        backgroundSize: '470px 268px',

        [theme.breakpoints.up('sm')]: {
            backgroundSize: '847px 483px',
        },
    },
    heroContent: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: '40px',
    },
    heroText: {
        color: 'white',
        textAlign: 'center',
        fontSize: pxToRem(35),
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(45),
        },
    },
    heroButton: {},
    quizLink: {
        textDecoration: 'none',
        color: 'inherit',
    }
}));

export type QuizCTAProps = {
    quizLink: string;
};

const QuizCTA: FC<QuizCTAProps> = ({ quizLink }) => {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <div className={classes.root} data-qa="quiz-cta">
            <div className={classes.heroBg}>
                <div className={classes.heroContent}>
                    <Typography
                        data-qa="quiz-cta-title"
                        className={classes.heroText}
                        component="h1"
                        variant="h2"
                    >
                        {intl.formatMessage({
                            id: 'landingHero.actionPlan.text',
                            defaultMessage: 'landingHero.actionPlan.text',
                        })}
                    </Typography>
                    <Button
                        className={classes.heroButton}
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `SEIU Landing hero_${intl.formatMessage(
                                    {
                                        id: 'landingHero.actionPlan.button',
                                    },
                                )}_top`,
                            });
                        }}
                        // component={Link}
                        // to={quizLink}
                        variant="outlined"
                        data-qa="quiz-cta-button"
                    >
                        <a
                            href={quizLink}
                            className={classes.quizLink}
                        >
                            {intl.formatMessage({
                                id: 'landingHero.actionPlan.button',
                                defaultMessage: 'landingHero.actionPlan.button',
                            })}
                        </a>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default QuizCTA;
