import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from '../../theme/font';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'theme/theme';
import { customClickEvent } from 'analytics';
import { heroHeight, heroWidth, siteWidth } from 'utils/siteWidthUtils';

interface FullWidthCTAProps {
    title: string[] | string;
    bgImagePath: string;
    bgMobileImagePath: string;
    subTitle: string;
    ctaLabel: string;
    ctaURL: string;
    height?: number;
    dataQA: string;
    contentBottomImagepath?: string;
}

const useStyles = makeStyles((theme) => ({
    bgColor: {
        backgroundColor: 'white',
    },
    root: {
        maxWidth: heroWidth,
        minHeight: heroHeight,
        margin: '0px auto',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        color: theme.colors.white,
        textAlign: 'center',
        width: '100%',
        paddingLeft: 'unset',
        margin: 'unset',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 43,
            textAlign: 'unset',
            maxWidth: siteWidth,
            margin: '0px auto',
        },
    },
    textContent: {
        paddingBottom: 10,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: 20,
        },
    },
    title: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        weight: 600,
        lineHeight: '30px',
        fontSize: pxToRem(23),
        [theme.breakpoints.down(400)]: {
            fontSize: pxToRem(21.5),
        },
        [theme.breakpoints.up('sm')]: {
            weight: 600,
            lineHeight: pxToRem(30),
            fontSize: pxToRem(45),
        },
    },
    subTitle: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        weight: 400,
        lineHeight: '24px',
        fontSize: pxToRem(17),
        [theme.breakpoints.up('sm')]: {
            weight: 400,
            lineHeight: '25px',
            fontSize: pxToRem(30),
        },
    },
    bottomImageWrapper: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            marginTop: 15,
            display: 'block',
        },
    },
}));

const FullWidthCTA: React.FC<FullWidthCTAProps> = ({
    title,
    bgImagePath,
    subTitle,
    ctaLabel,
    ctaURL,
    height = 500,
    bgMobileImagePath,
    dataQA,
    contentBottomImagepath = null,
}) => {
    const classes = useStyles();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const renderTitle = () => {
        if (typeof title === 'string') {
            return <h1 className={classes.title}>{title}</h1>;
        } else {
            return title.map((line, index) => {
                return (
                    <h1 key={index} className={classes.title}>
                        {line}
                    </h1>
                );
            });
        }
    };

    return (
        <div className={classes.bgColor} data-qa="fullwidth-hero">
            <div
                className={classes.root}
                style={{
                    backgroundImage: `url('${
                        isMobile ? bgMobileImagePath : bgImagePath
                    }')`,
                    height: isMobile ? 500 : height,
                }}
            >
                <div className={classes.content}>
                    <div className={classes.textContent}>
                        {renderTitle()}
                        <h1 className={classes.subTitle}>{subTitle}</h1>
                    </div>
                    <Button
                        data-qa={dataQA}
                        variant="outlined"
                        size="small"
                        to={ctaURL}
                        component={Link}
                        onClick={() => {
                            customClickEvent({
                                customLinkName: `Fullwidth Hero: ${ctaLabel}`,
                            });
                        }}
                    >
                        {ctaLabel}
                    </Button>
                    {contentBottomImagepath && (
                        <div className={classes.bottomImageWrapper}>
                            <img
                                alt={title.toString()}
                                src={contentBottomImagepath}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FullWidthCTA;
