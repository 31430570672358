import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import MobileView from './MobileView';
import DesktopView from './DesktopView';

const ThreeLeggedStool: FC = () => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    if (isMobile) {
        return <MobileView />;
    }
    return <DesktopView />;
};

export default ThreeLeggedStool;
