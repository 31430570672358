import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'components/carousel';
import { Theme, makeStyles } from '@material-ui/core/styles';

/* components */
import BaseHero from 'components/hero-cta/BaseHero';
import FullWidthCTA from 'components/hero-cta/FullWidthCTA';
import SplitFullWidthCTA from 'components/hero-cta/SplitFullWidthCTA';
import WelcomeVideo from './WelcomeVideo';
import QuizCTA from './QuizCTA';

/* store */
import { RootState } from 'store';
import { getFullCtaLink } from 'utils/url';

import SpanishHero from 'assets/spanish-hero-bg.png';
import clsx from 'clsx';
import { pxToRem } from 'theme/util';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.colors.white,
        '& .homepage-mlk-promo-baseHeroCta-wrapper': {
            '& .baseHeroCta-image': {
                [theme.breakpoints.between('xs', 391)]: {
                    maxWidth: 220,
                },
                [theme.breakpoints.between(391, 415)]: {
                    maxWidth: 280,
                },
                [theme.breakpoints.up(415)]: {
                    maxWidth: 304,
                },
            },
            '& .baseHeroCta-content': {
                '& .MuiTypography-root': {
                    color: theme.colors.black,
                },
                '& .baseHeroCta-boldText': {
                    fontSize: pxToRem(16),
                    lineHeight: pxToRem(24),
                    marginBottom: theme.spacing(1),
                    [theme.breakpoints.up('sm')]: {
                        marginBottom: theme.spacing(2.5),
                        fontSize: pxToRem(20),
                        lineHeight: pxToRem(28),
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: pxToRem(30),
                        lineHeight: pxToRem(38),
                    },
                },
                '& .baseHeroCta-text': {
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(18),
                    [theme.breakpoints.up('sm')]: {
                        fontSize: pxToRem(16),
                        lineHeight: pxToRem(24),
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: pxToRem(24),
                        lineHeight: pxToRem(32),
                    },
                },
                '& .baseHeroCta-button': {
                    margin: theme.spacing(1, 0),
                    [theme.breakpoints.up('sm')]: {
                        margin: theme.spacing(2.5, 0),
                    },
                },
            },
        },
        '& .homepage-mlk-promo-baseHeroCta-content': {
            '& .MuiTypography-root': {
                color: theme.colors.black,
            },
            '& .baseHeroCta-boldText': {
                fontSize: pxToRem(20),
                lineHeight: pxToRem(28),
                [theme.breakpoints.up('lg')]: {
                    fontSize: pxToRem(30),
                    lineHeight: pxToRem(38),
                },
            },
            '& .baseHeroCta-text': {
                fontSize: pxToRem(16),
                lineHeight: pxToRem(24),
                [theme.breakpoints.up('lg')]: {
                    fontSize: pxToRem(24),
                    lineHeight: pxToRem(32),
                },
            },
        },
    },
    dots: {
        position: 'relative',
        height: theme.spacing(2.75),
        marginTop: theme.spacing(-2.75),
        '& button': {
            height: 10,
            width: 10,
            marginLeft: 14,
            [theme.breakpoints.up('sm')]: {
                marginLeft: 16,
            },
        },
    },
    arrows: {
        background: `var(--text-primary-on-dark, ${theme.colors.white})`,
        height: 50,
        width: 30,
        fontSize: 20,
        paddingTop: 10,
        position: 'absolute',
        top: 220,
        zIndex: 9,
        boxShadow:
            '0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
        cursor: 'pointer',
    },
    leftArrow: {
        left: 0,
    },
    rightArrow: {
        right: 0,
    },
}));

type HeroCarouselProps = {
    videoConfig: any;
};

const HeroCarousel: FC<HeroCarouselProps> = ({ videoConfig }) => {
    const classes = useStyles();

    const company = useSelector((state: RootState) => state.company.theme);

    const quizLink = company ? getFullCtaLink('/quiz', company.companyId, '/retirement/') : '';
    const grammyLink = company ? `/${company.companyId}/grammy-major` : '';
    const latinGrammyLink = company ? `/${company.companyId}/latin-grammy` : '';

    const leftCtaProps = {
        title: 'Money, music and your future',
        mobileTitle:
            'A conversation with artist, actor and humanitarian MAJOR.',
        bgImagePath:
            '/content/dam/my-retirement/images/Major-split-hero-large.png',
        bgMobileImagePath:
            '/content/dam/my-retirement/images/Major-split-hero-mobile.png',
        subTitle: 'A conversation with artist, actor and humanitarian MAJOR.',
        ctaLabel: 'MEET MAJOR.',
        ctaURL: grammyLink,
        bgColor: `#20344D`,
        accentImagepath: '/content/dam/my-retirement/images/grammy-museum.png',
    };
    const rightCtaProps = {
        title: 'Money, music and your future',
        mobileTitle: 'Here’s how two musicians learned to save “para mañana”',
        bgImagePath:
            '/content/dam/my-retirement/images/hispanic-split-desktop-v2.jpg',
        bgMobileImagePath:
            '/content/dam/my-retirement/images/hispanic-split-mobile-v2.jpg',
        subTitle: 'Here’s how two musicians learned to save “para mañana”',
        ctaLabel: 'Meet Claudia and Cheche',
        ctaURL: latinGrammyLink,
        bgColor: '#020204',
        accentImagepath: '/content/dam/my-retirement/images/grammy-museum.png',
        centeredContent: true,
    };
    return (
        <div className={classes.root} data-qa="hero-carousel">
            <Carousel
                arrowWithBackground
                arrowWithBackgroundLeftClass={clsx(
                    classes.arrows,
                    classes.leftArrow,
                )}
                arrowWithBackgroundRightClass={clsx(
                    classes.arrows,
                    classes.rightArrow,
                )}
                dotClassName={classes.dots}
                invertDotColors
            >
                <BaseHero
                    customLink={`mlk-promo: MLK Promo`}
                    ctaProps={{
                        'data-testid': `homepage-mlk-promo-button`,
                        'data-qa': `homepage-mlk-promo-button`,
                    }}
                    ctaText="GET INSPIRED"
                    ctaUrl={`/${company?.companyId}/grammy-major?video=4#watch`}
                    dataQA="homepage-mlk-promo"
                    imageAlt="Three brushed stripes, red, gold, and green, on a black background."
                    imagePath="/content/dam/my-retirement/images/mlk-hero-art.jpg"
                    boldText="“If you can’t fly, then run. If you can’t run, then
                    walk. If you can’t walk, then crawl. But whatever you
                    do, you have to keep moving forward.”<br />&mdash; Martin Luther
                    King Jr."
                    text="Let Dr. King’s words encourage you as you plan for the future. Keep saving as much as you can to move closer to your retirement dream. Now, realize your future possibilities through inspiration from <strong>GRAMMY-nominated artist MAJOR.</strong>"
                    key="MLK-hero"
                    isHeroHeight
                />
                <FullWidthCTA
                    title={`Building wealth "para su familia"`}
                    bgImagePath={SpanishHero}
                    bgMobileImagePath={SpanishHero}
                    subTitle="Saving for retirement — and a better future"
                    ctaLabel="EXPLORE THE EXPERIENCE"
                    ctaURL={`/${company?.companyId}/hispanic`}
                    dataQA="homepage-promo-hispanic"
                />
                <SplitFullWidthCTA
                    key="grammy-promo"
                    leftCtaProps={leftCtaProps}
                    rightCtaProps={rightCtaProps}
                    dataQA="homepage-promo-button-grammy"
                />
                <WelcomeVideo videoConfig={videoConfig} />
                <QuizCTA quizLink={quizLink} />
            </Carousel>
        </div>
    );
};

export default HeroCarousel;
