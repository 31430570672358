import React, { FC } from 'react';

/* material */
import { Box } from '@material-ui/core';

/* component */
import ArticleImage from './ArticleImage';

/* utils */
import { pxToSpacing } from 'theme/util';
import { aemBaseURL } from 'constants/aem';
import { parseReplace } from 'utils/htmlParser';

/* types */
import { ArticleContentFragment } from './article.types';

type ArticleContentBodyProps = {
    fragment: ArticleContentFragment;
};

const ArticleContentBody: FC<ArticleContentBodyProps> = ({ fragment }) => {
    const { content, articleImagePath, alt } = fragment;
    const src = `${aemBaseURL}${articleImagePath}`;

    return (
        <>
            {articleImagePath && (
                <Box mb={pxToSpacing(32)}>
                    <ArticleImage url={src} alt={alt} />
                </Box>
            )}
            <Box data-testid="article-content-body">
                {parseReplace(content)}
            </Box>
        </>
    );
};

export default ArticleContentBody;
