import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { useIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { parseReplace } from '../../utils/htmlParser';

/* material */
import { Box, Link as MaterialLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* components */
import { Disclosures } from './disclosures';
import { asyncLoad } from 'components';

/* utils */
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import colors from 'theme/colors';
import { pxToRem, pxToSpacing } from 'theme/util';
import { getFullCtaLink } from 'utils/url';

/* types */
import { Theme } from 'reducers/company';
import { User } from 'reducers/user';

/* assets */
import adChoicesSvg from 'assets/adchoices.svg';

/* selectors */
import { showCompanyResourcesLinkSelector } from 'selectors/companySelector';
import { customClickEvent } from 'analytics';
import { siteWidth } from 'utils/siteWidthUtils';

const CompanyResources = asyncLoad.component(
    'footer/CompanyResources/CompanyResources',
    () => <></>,
);

const footerLinkStyle = {
    color: colors.symphonyBlue,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    marginBottom: pxToRem(8),
};

const useStyle = makeStyles((theme) => ({
    root: {
        padding: '0px 20px',
        backgroundColor: colors.gray97,
        borderTop: `solid 1px ${colors.gray70}`,
        '& a': {
            textDecoration: 'none',
        },
    },
    footerLinks: {
        '& a': {
            display: 'block',
            fontFamily: AVENIR_NEXT_LT_DEMI,
            ...footerLinkStyle,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    footerNavLinks: {
        breakInside: 'avoid',
        margin: 0,
        columnCount: 2,
        columnGap: '90px',
        [theme.breakpoints.up('sm')]: {
            columnGap: '110px',
        },
    },
    referenceLinks: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        whiteSpace: 'nowrap',
        ...footerLinkStyle,
    },
    evidonNotice: {
        '& a': {
            ...footerLinkStyle,
            color: `${footerLinkStyle.color} !important`,
            fontSize: `${footerLinkStyle.fontSize} !important`,
            textTransform: 'lowercase',
        },
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
}));

type FooterProps = {
    company: Theme;
    user: User;
};

export const Footer: FC<FooterProps> = ({ company, user }) => {
    const classes = useStyle();
    const intl = useIntl();
    const { showCompanyResourcesOnFooter } = useFlags();
    const [shouldShowBorder, setShouldShowBorder] = useState<boolean>(true);
    const companyId = company.companyId;
    const showCompanyResourcesLink = useSelector(
        showCompanyResourcesLinkSelector,
    );

    if (!company) return null;

    const clientIntranetLabel = company.clientIntranetSiteLabel;
    const clientIntranetUrl = company.clientIntranetSiteUrl;
    const isPlanDetailsLinkVisible = company.showPlanDetails;
    const isContactUsLinkVisible = company.showContactUs;
    const isTargetDateLinkVisibile = company.showTargetDate;
    const planDetailsUrl = company.planDetailsUrl;
    const domainName = user.domain.domainName;

    //this is needed only for when the user is already in make a change page
    const scrollWithOffset = (el: HTMLElement) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div id="footer-wrapper">
                <Box data-qa="footer" className={`${classes.root}`}>
                    <Box
                        color={colors.gray70}
                        borderBottom={shouldShowBorder ? 1 : 0}
                        pb={4}
                        mx="auto"
                        style={{ maxWidth: siteWidth }}
                    //width={{ xs: '100%', lg: siteWidth }}
                    >
                        <Box
                            ml={{ xs: pxToSpacing(20), lg: 0 }}
                            mr={{ xs: pxToSpacing(20), lg: 0 }}
                            mt={{ xs: pxToSpacing(32), md: pxToSpacing(40) }}
                        >
                            <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                            >
                                <Box
                                    className={classes.footerLinks}
                                    component="nav"
                                    display="flex"
                                    justifyContent="flex-start"
                                    minWidth={pxToRem(340)}
                                    mb={{ xs: pxToSpacing(28), sm: 0 }}
                                    mr={{
                                        xs: 0,
                                        sm: pxToSpacing(48),
                                        md: pxToSpacing(96),
                                    }}
                                >
                                    <Box
                                        mr={6}
                                        className={classes.footerNavLinks}
                                    >
                                        <a
                                            data-qa="get-my-action-plan-footer-link"
                                            data-dig-nav="Footer"
                                            onClick={() => {
                                                customClickEvent({
                                                    customLinkName: `Footer: ${intl.formatMessage(
                                                        {
                                                            id: 'footer.links.getMyPlan',
                                                        },
                                                    )}`,
                                                });
                                            }}
                                            href={getFullCtaLink(
                                                '/quiz', companyId, '/retirement/'
                                            )}
                                        >
                                            <FormattedMessage id="footer.links.getMyPlan" />
                                        </a>
                                        <Link
                                            data-qa="get-the-facts-footer-link"
                                            data-dig-nav="Footer"
                                            onClick={() => {
                                                customClickEvent({
                                                    customLinkName: `Footer: ${intl.formatMessage(
                                                        {
                                                            id: 'footer.links.getTheFacts',
                                                        },
                                                    )}`,
                                                });
                                            }}
                                            to={`/${companyId}/get-the-facts`}
                                        >
                                            <FormattedMessage id="footer.links.getTheFacts" />
                                        </Link>
                                        <Link
                                            data-qa="run-the-numbers-footer-link"
                                            data-dig-nav="Footer"
                                            onClick={() => {
                                                customClickEvent({
                                                    customLinkName: `Footer: ${intl.formatMessage(
                                                        {
                                                            id: 'footer.links.runTheNumbers',
                                                        },
                                                    )}`,
                                                });
                                            }}
                                            to={`/${companyId}/calculator`}
                                        >
                                            <FormattedMessage id="footer.links.runTheNumbers" />
                                        </Link>
                                        {showCompanyResourcesLink && (
                                            <Link
                                                data-qa="company-resources-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${intl.formatMessage(
                                                            {
                                                                id: 'footer.links.companyResources',
                                                            },
                                                        )}`,
                                                    });
                                                }}
                                                to={`/${company.companyId}/make-a-change`}
                                                scroll={(el) =>
                                                    scrollWithOffset(el)
                                                }
                                            >
                                                <FormattedMessage id="footer.links.companyResources" />
                                            </Link>
                                        )}
                                        {isTargetDateLinkVisibile && (
                                            <Link
                                                data-qa="target-date-footer-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${intl.formatMessage(
                                                            {
                                                                id: 'footer.links.targetDate',
                                                            },
                                                        )}`,
                                                    });
                                                }}
                                                to={`/${companyId}/target-date`}
                                            >
                                                <FormattedMessage id="footer.links.targetDate" />
                                            </Link>
                                        )}
                                        <Link
                                            data-qa="faqs-footer-link"
                                            data-dig-nav="Footer"
                                            onClick={() => {
                                                customClickEvent({
                                                    customLinkName: `Footer: ${intl.formatMessage(
                                                        {
                                                            id: 'footer.links.faqs',
                                                        },
                                                    )}`,
                                                });
                                            }}
                                            to={`/${companyId}/faqs`}
                                        >
                                            <FormattedMessage id="footer.links.faqs" />
                                        </Link>
                                        {isContactUsLinkVisible && (
                                            <Link
                                                data-qa="contact-us-footer-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${intl.formatMessage(
                                                            {
                                                                id: 'footer.links.contactUs',
                                                            },
                                                        )}`,
                                                    });
                                                }}
                                                to={`/${companyId}/contact-us`}
                                            >
                                                <FormattedMessage id="footer.links.contactUs" />
                                            </Link>
                                        )}

                                        {clientIntranetUrl && (
                                            <Link
                                                data-qa="client-intranet-footer-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${clientIntranetLabel}`,
                                                    });
                                                }}
                                                target="_blank"
                                                rel="nofollow noopener"
                                                to={{
                                                    pathname: clientIntranetUrl,
                                                }}
                                            >
                                                {clientIntranetLabel}
                                            </Link>
                                        )}
                                        {isPlanDetailsLinkVisible && (
                                            <Link
                                                data-qa="plan-details-footer-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${intl.formatMessage(
                                                            {
                                                                id: 'header.planDetails',
                                                            },
                                                        )}`,
                                                    });
                                                }}
                                                target="_blank"
                                                rel="nofollow noopener"
                                                to={{
                                                    pathname: planDetailsUrl,
                                                }}
                                            >
                                                <FormattedMessage id="header.planDetails" />
                                            </Link>
                                        )}
                                    </Box>
                                </Box>

                                <Box>
                                    <Box
                                        color={colors.symphonyBlue}
                                        fontFamily={AVENIR_NEXT_LT_DEMI}
                                        mb={{
                                            xs: pxToSpacing(36),
                                            md: pxToSpacing(40),
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            fontFamily={AVENIR_NEXT_LT_DEMI}
                                            fontSize={{
                                                xs: pxToRem(22),
                                                sm: pxToRem(24),
                                            }}
                                            mb={2}
                                        >
                                            <Link
                                                data-qa="my-retirement-footer-link"
                                                data-dig-nav="Footer"
                                                onClick={() => {
                                                    customClickEvent({
                                                        customLinkName: `Footer: ${domainName}`,
                                                    });
                                                }}
                                                style={{
                                                    color: colors.darkTurquoise,
                                                }}
                                                to={`/${companyId}`}
                                            >
                                                {domainName === 'ICanRetire'
                                                    ? `${domainName}${parseReplace(
                                                        '&#174;',
                                                    )}`
                                                    : domainName}
                                            </Link>
                                        </Box>
                                        <span data-qa="domainTagline">
                                            {intl.formatMessage({
                                                id: `domain.${domainName.toLowerCase()}.tagline`,
                                            })}
                                        </span>
                                    </Box>
                                    <Box
                                        mb={pxToSpacing(8)}
                                        color={colors.darkSapphire}
                                    >
                                        <FormattedMessage
                                            id="footer.copyright"
                                            values={{
                                                year: currentYear,
                                            }}
                                        />
                                    </Box>
                                    <Box display="flex" flexWrap="wrap">
                                        <Box
                                            mb={pxToSpacing(8)}
                                            display="flex"
                                            justifyContent="flex-start"
                                        >
                                            <Box mr={pxToSpacing(60)}>
                                                <MaterialLink
                                                    data-qa="privacy-policy-footer-link"
                                                    data-dig-nav="Footer"
                                                    onClick={() => {
                                                        customClickEvent({
                                                            customLinkName: `Footer: ${intl.formatMessage(
                                                                {
                                                                    id: 'footer.privacyPolicy',
                                                                },
                                                            )}`,
                                                        });
                                                    }}
                                                    className={
                                                        classes.referenceLinks
                                                    }
                                                    href="/privacy"
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    {intl.formatMessage({
                                                        id: 'footer.privacyPolicy',
                                                    })}
                                                </MaterialLink>
                                            </Box>
                                            <Box mr={pxToSpacing(60)}>
                                                <MaterialLink
                                                    data-qa="ad-choices-footer-link"
                                                    data-dig-nav="Footer"
                                                    onClick={() => {
                                                        customClickEvent({
                                                            customLinkName: `Footer: ${intl.formatMessage(
                                                                {
                                                                    id: 'footer.addChoices',
                                                                },
                                                            )}`,
                                                        });
                                                    }}
                                                    className={
                                                        classes.referenceLinks
                                                    }
                                                    href="https://l3.evidon.com/site/4122/23558/1"
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    <Box
                                                        alignItems="center"
                                                        display="flex"
                                                    >
                                                        <Box
                                                            alignItems="center"
                                                            display="flex"
                                                            mr={0.5}
                                                        >
                                                            <img
                                                                alt="adchoices"
                                                                src={
                                                                    adChoicesSvg
                                                                }
                                                                style={{
                                                                    width: '15px',
                                                                }}
                                                            />
                                                        </Box>
                                                        {intl.formatMessage({
                                                            id: 'footer.addChoices',
                                                        })}
                                                    </Box>
                                                </MaterialLink>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <div
                                                className={clsx(
                                                    classes.evidonNotice,
                                                    'evidon-notice-link',
                                                )}
                                            ></div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {showCompanyResourcesOnFooter && (
                    <CompanyResources
                        onLinksReady={() => {
                            setShouldShowBorder(false);
                        }}
                    />
                )}

                <Disclosures />
            </div>
        </>
    );
};
