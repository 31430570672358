import * as React from 'react';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import HeroVideoModal from 'components/heroVideoModal/HeroVideoModal';
import { customClickEvent } from 'analytics';
import VideoPreview from 'assets/seiuWelcomeDesktop.png';
import MobileVideoPreview from 'assets/seiuWelcomeMobile.png';
import { pxToRem } from 'theme/util';
import { AVENIR_NEXT_LT_DEMI, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import colors from 'theme/colors';
import { heroHeight, heroWidth, siteWidth } from 'utils/siteWidthUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        height: heroHeight,
        maxWidth: heroWidth,
        overflow: 'hidden',
        margin: '0px auto',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${MobileVideoPreview})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',
        backgroundColor: 'rgba(39, 39, 39, 0.6);',
        backgroundBlendMode: 'darken',
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${VideoPreview})`,
            justifyContent: 'space-between',
            backgroundPosition: 'center',
        },
    },
    heroBg: {
        margin: '0px auto',
        width: siteWidth,
        height: heroHeight,
        position: 'relative',
    },
    rightTextWrapper: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: 0,
            top: 168,
            width: 400,
            textAlign: 'center',
        },
    },
    heroText: {
        color: 'white',
        textAlign: 'center',
        fontSize: pxToRem(35),
        lineHeight: pxToRem(45),
        maxWidth: '350px',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(45),
            maxWidth: '550px',
        },
    },
    leftTextWrapper: {
        position: 'relative',
        top: 390,
        left: 10,
        width: 320,

        [theme.breakpoints.up('sm')]: {
            top: 390,
            left: 20,
            width: 420,
            minWidth: 420,
        },
    },
    subtextTitle: {
        fontFamily: AVENIR_NEXT_LT_DEMI,
        fontWeight: 600,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(25),
        color: colors.white,
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(30),
            lineHeight: pxToRem(33),
        },
    },
    subtextDesc: {
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(25),
        color: colors.white,
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(25),
            lineHeight: pxToRem(33),
        },
    },
    button: {
        marginTop: 20,
    },
}));

type WelcomeVideoProps = {
    videoConfig: { [key: string]: string };
};

const WelcomeVideo: FC<WelcomeVideoProps> = ({ videoConfig }) => {
    const intl = useIntl();
    const classes = useStyles();
    const [isOpenModal, setOpenModal] = useState(false);

    const openModal = () => {
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    if (!videoConfig) {
        return null;
    }

    return (
        <div className={classes.root} data-qa="welcome-video">
            <div className={classes.heroBg}>
                <div className={classes.leftTextWrapper}>
                    <Typography
                        data-qa="welcome-video-subtitle"
                        className={classes.subtextTitle}
                        variant="h3"
                        component="h2"
                    >
                        {intl.formatMessage({
                            id: 'landingHero.welcomeVideo.subtext.title',
                            defaultMessage:
                                'landingHero.welcomeVideo.subtext.title',
                        })}
                    </Typography>
                    <Typography
                        data-qa="welcome-video-subtitle"
                        className={classes.subtextDesc}
                        variant="h4"
                        component="h2"
                    >
                        {intl.formatMessage({
                            id: 'landingHero.welcomeVideo.subtext.desc',
                            defaultMessage:
                                'landingHero.welcomeVideo.subtext.desc',
                        })}
                    </Typography>
                </div>
                <div className={classes.rightTextWrapper}>
                    <Typography
                        data-qa="welcome-video-title"
                        className={classes.heroText}
                        variant="h1"
                        component="h2"
                    >
                        {intl.formatMessage({
                            id: 'landingHero.welcomeVideo.title',
                            defaultMessage: 'landingHero.welcomeVideo.title',
                        })}
                    </Typography>
                    <Button
                        className={classes.button}
                        data-qa="play-video-button"
                        variant="outlined"
                        onClick={() => {
                            openModal();
                            customClickEvent({
                                customLinkName: `Video: ${intl.formatMessage({
                                    id: 'playVideo',
                                })}`,
                            });
                        }}
                    >
                        <FormattedMessage
                            id="playVideo"
                            defaultMessage="playVideo"
                        />
                    </Button>
                </div>
            </div>
            <HeroVideoModal
                params={videoConfig}
                isOpen={isOpenModal}
                onClose={closeModal}
                data-qa="hero-video-modal"
            />
        </div>
    );
};

export default WelcomeVideo;
