import { GetCompanyAction } from 'actions';
import { GENERIC } from 'constants/company';
import getAEMBaseUrl from 'utils/getAEMBaseUrl';
const companies = {
    fetchCompany: (params: GetCompanyAction) =>
        fetch(
            getAEMBaseUrl(
                `/content/company-configs?companies=my-retirement:companies/${params.companyId}`,
            ),
            {
                method: 'GET',
            },
        ),
    fetchCompanyResources: (companyId: string) =>
        fetch(
            getAEMBaseUrl(
                `/content/company-resources?companies=my-retirement:companies/${companyId}`,
            ),
            {
                method: 'GET',
            },
        ),
    fetchDefaultCompany: () =>
        fetch(
            getAEMBaseUrl(
                `/content/company-configs?companies=my-retirement:companies/${GENERIC}`,
            ),
            {
                method: 'GET',
            },
        ),
};

export default companies;
