import React, { FC } from 'react';

/* material */
import { makeStyles, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

/* components */
import FixedAspectRatio from 'components/aspect-ratio/AspectRatio';
import DynamicMediaPlayer from 'components/dynamic-media-player/DynamicMediaPlayer';

/* utils */
import { pxToRem } from 'theme/util';

const useStyle = makeStyles((theme) => ({
    modalContent: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    contentWrapper: {
        height: 'auto',
        padding: `${pxToRem(60)} ${pxToRem(20)}`,
        position: 'relative',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            padding: `${pxToRem(60)} ${pxToRem(50)}`,
        },
    },
    content: {
        margin: '0 auto',

        [theme.breakpoints.up('sm')]: {
            maxWidth: 885,
        },

        /*
            Media player with transcripts is rendered from AEM fragment using html parser.
            There is no direct access to overwrite styles at the moment due to the fact that
            the custom tags are not setup to accept custom parameters.
        */
        "& div[data-qa='dynamic-media-player'] span": {
            left: 'unset',
            marginLeft: 'unset',
            marginRight: 'unset',
            right: 'unset',
            width: '100%',
        },
    },
    icon: {
        backgroundColor: 'transparent',
        border: 'none',
        right: 5,
        position: 'absolute',
        top: 10,
    },
    videoWrapper: {
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
}));

interface HeroVideoModal {
    isOpen: boolean;
    params: { [key: string]: string } | undefined;
    onClose: () => void;
}

const HeroVideoModal: FC<HeroVideoModal> = ({ isOpen, params, onClose }) => {
    const classes = useStyle();

    return (
        <Modal
            aria-labelledby="money-minute-article-id"
            open={isOpen}
            disableScrollLock={false}
        >
            <div data-qa="money-minute-modal" className={classes.modalContent}>
                <div className={classes.contentWrapper}>
                    <button
                        data-qa="money-minute-modal-close-btn"
                        className={classes.icon}
                        onClick={onClose}
                    >
                        <CloseIcon htmlColor="white" fontSize="large" />
                    </button>
                    <div
                        data-qa="money-minute-modal-content"
                        className={classes.content}
                    >
                        <div className={classes.videoWrapper}>
                            <FixedAspectRatio>
                                {params && (
                                    <DynamicMediaPlayer params={params} />
                                )}
                            </FixedAspectRatio>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default HeroVideoModal;
