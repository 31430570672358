import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { AVENIR_NEXT_LT_BOLD, AVENIR_NEXT_LT_REGULAR } from 'theme/font';
import { pxToRem } from 'theme/util';
import colors from 'theme/colors';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 290,
        margin: 'auto',
        width: '100%',
        backgroundColor: colors.mint,
        borderBottom: '1px solid lightgray',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            height: 310,
        },
    },
    title: {
        fontWeight: 700,
        fontFamily: AVENIR_NEXT_LT_BOLD,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(18),
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        paddingTop: 40,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 70,
        },
    },
    text: {
        color: colors.gunmetal,
        fontFamily: AVENIR_NEXT_LT_REGULAR,
        fontSize: pxToRem(22),
        lineHeight: pxToRem(29),
        width: 320,
        margin: '30px auto',
        [theme.breakpoints.up('sm')]: {
            width: 450,
        },
    },
}));

const RegisterCTA: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    const handleRedirect = () => {
        customClickEvent({
            customLinkName: `SEIU Landing_${intl.formatMessage({
                id: 'registerCTA.3',
            })}_bottom`,
        });
        const url =
            'https://login.millimanonline.com/Logon?d=hPL139%2fdvo%2bPk5acnouWkJHdxd%2fNzdPy9d%2ff3baMsJKRlrCRk5aRmqianbuajIuWkZ6LlpCR3cXfi42KmtPy9d%2ff3baMsoqTi5aPk5qqjJqNq4aPmruajIuWkZ6LlpCR3cXfi42KmvL1gg%3d%3d';
        const target = '_blank';

        window.open(url, target);
    };

    return (
        <div className={classes.root} data-qa="register-cta">
            <Typography className={classes.title}>
                <FormattedMessage
                    id="registerCTA.1"
                    defaultMessage="registerCTA.1"
                />
            </Typography>
            <Typography className={classes.text}>
                <FormattedMessage
                    id="registerCTA.2"
                    defaultMessage="registerCTA.2"
                />
            </Typography>
            <Button
                data-qa="register-to-view"
                variant="contained"
                onClick={handleRedirect}
                size="medium"
            >
                <FormattedMessage
                    id="registerCTA.3"
                    defaultMessage="registerCTA.3"
                />
            </Button>
        </div>
    );
};

export default RegisterCTA;
