import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const cleanHash = (hash: string) => {
    const regex = /#([^?]+)/;
    const match = hash.match(regex);
    const extractedValue = match ? match[1] : hash;
    return extractedValue;
};

const useHashScroll = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHashScroll = () => {
            const { hash } = location;
            if (hash) {
                const hash = cleanHash(location.hash);
                const element = document.getElementById(hash);
                if (element) {
                    const yCoordinate =
                        element.getBoundingClientRect().top +
                        window.pageYOffset;
                    const yOffset = -70;
                    window.scrollTo({
                        top: yCoordinate + yOffset,
                        behavior: 'smooth',
                    });
                }
            } else {
                // Scroll to the top of the page if no hash is present
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        // Call the handleHashScroll function on initial mount and whenever location or location.hash changes
        handleHashScroll();

        // Scroll to the correct section when the hash changes
        window.addEventListener('hashchange', handleHashScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('hashchange', handleHashScroll);
        };
    }, [location]);
};

export default useHashScroll;
