import FlipCard from 'components/flip-card/FlipCard';
import { Button, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import Carousel from 'components/carousel/Carousel';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import useHashScroll from 'hooks/useScrollHash';
import { customClickEvent } from 'analytics';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '35px',
        lineHeight: '46px',
        textAlign: 'center',
        paddingTop: '40px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            lineHeight: '32px',
        },
    },
    subHeader: {
        fontSize: '22px',
        lineHeight: '46px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        gridGap: '5px',
    },
    openIRACtaContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px',
    },
    iraReasons: {
        marginTop: '0px',
    },
}));

const LearnAboutIRA: FC = () => {
    const classes = useStyles();
    const intl = useIntl();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );
    const renderIraReasons = () => {
        return (
            <>
                <ul className={classes.iraReasons}>
                    <li>
                        {intl.formatMessage({
                            id: 'learnAboutIRA.flipCards.iraReason1',
                            defaultMessage:
                                'learnAboutIRA.flipCards.iraReason1',
                        })}
                    </li>
                    <li>
                        {intl.formatMessage({
                            id: 'learnAboutIRA.flipCards.iraReason2',
                            defaultMessage:
                                'learnAboutIRA.flipCards.iraReason2',
                        })}
                    </li>
                    <li>
                        {intl.formatMessage({
                            id: 'learnAboutIRA.flipCards.iraReason3',
                            defaultMessage:
                                'learnAboutIRA.flipCards.iraReason3',
                        })}
                    </li>
                </ul>
            </>
        );
    };
    const cards = [
        {
            imagePath: '',
            question: intl.formatMessage({
                id: 'learnAboutIRA.flipCards.whatIsIra',
                defaultMessage: 'learnAboutIRA.flipCards.whatIsIra',
            }),
            answer: intl.formatMessage({
                id: 'learnAboutIRA.flipCards.iraDef',
                defaultMessage: 'learnAboutIRA.flipCards.iraDef',
            }),
            isCardFlipped: false,
            key: 'card-1',
        },
        {
            imagePath: '',
            question: intl.formatMessage({
                id: 'learnAboutIRA.flipCards.whyIra',
                defaultMessage: 'learnAboutIRA.flipCards.whyIra',
            }),
            answer: renderIraReasons(),
            isCardFlipped: false,
            key: 'card-2',
        },
        {
            imagePath: '',
            question: intl.formatMessage({
                id: 'learnAboutIRA.flipCards.howToGetStarted',
                defaultMessage: 'learnAboutIRA.flipCards.howToGetStarted',
            }),
            answer: intl.formatMessage({
                id: 'learnAboutIRA.flipCards.getStartedAns',
                defaultMessage: 'learnAboutIRA.flipCards.getStartedAns',
            }),
            isCardFlipped: false,
            key: 'card-3',
        },
    ];

    const onCardFlip = (question: string, isFlipped: boolean) => {
        cards.forEach((item) => {
            if (item.question === question) {
                item.isCardFlipped = isFlipped;
            } else {
                item.isCardFlipped = false;
            }
        });
    };

    const renderMobileFlipCards = () => {
        const mobileCards: JSX.Element[] = [];
        cards.map((card) =>
            mobileCards.push(
                <FlipCard
                    key={card.key}
                    imagePath={card.imagePath}
                    question={card.question}
                    answer={card.answer}
                    flip={card.isCardFlipped}
                    toggleOnFlip={onCardFlip}
                />,
            ),
        );
        return mobileCards;
    };

    const handleOpenIRA = () => {

        customClickEvent({
            customLinkName: `SEIU Landing: ${intl.formatMessage({
                id: 'learnAboutIRA.openIra',
                defaultMessage: 'learnAboutIRA.openIra',
            })}`,
        });

        const url = 'https://retirement-marketplace.com/srt/';
        const target = '_blank';
        window.open(url, target);
    };

    useHashScroll();

    return (
        <div data-qa="learn-about-ira" id="iraflashcards">
            <header className={classes.header}>
                {intl.formatMessage({
                    id: 'learnAboutIRA.flipCards.learnAboutIRAs',
                    defaultMessage: 'learnAboutIRA.flipCards.learnAboutIRAs',
                })}
            </header>
            <p className={classes.subHeader}>
                {intl.formatMessage({
                    id: 'learnAboutIRA.flipCards.learnMoreAboutIRAs',
                    defaultMessage:
                        'learnAboutIRA.flipCards.learnMoreAboutIRAs',
                })}
            </p>

            {isMobile ? (
                <Carousel showArrows>{renderMobileFlipCards()}</Carousel>
            ) : (
                <div className={classes.container}>
                    {cards.map((card) => (
                        <FlipCard
                            key={card.key}
                            imagePath={card.imagePath}
                            question={card.question}
                            answer={card.answer}
                            flip={card.isCardFlipped}
                            toggleOnFlip={onCardFlip}
                        />
                    ))}
                </div>
            )}

            <div className={classes.openIRACtaContainer}>
                <Button
                    data-qa="open-ira"
                    variant="outlined"
                    onClick={handleOpenIRA}
                >
                    {intl.formatMessage({
                        id: 'learnAboutIRA.openIra',
                        defaultMessage: 'learnAboutIRA.openIra',
                    })}
                </Button>
            </div>
        </div>
    );
};

export default LearnAboutIRA;
